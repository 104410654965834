import React from 'react';
import Line from './Line';
import { getWidth, getHeight, getPositionXCenter, getPositionYCenter, getDefaultBoxSide, getDefaultShapeType } from '../../box-helpers';

// import {getPositionX, getPositionY} from '../../box-helpers';

class Shape extends React.Component {

  state = {
    // lines: [],
    boxSide: getDefaultBoxSide(),
    shapeType: getDefaultShapeType(),
    positionX: 0,
    positionY: 0,
    width: 10,
    height: 10,
  }

  constructor(props) {
    super(props);

    // this.state.lines = props.lines;
    this.state.boxSide = props.boxSide;
    this.state.shapeType = props.shapeType;
    this.state.positionX = props.positionX;
    this.state.positionY = props.positionY;
    this.state.width = props.width;
    this.state.height = props.height;

  }


  getPositionX() {
    let offset = 0;
    if (this.state.shapeType === "Rectangle") {
      offset = this.getWidth()/2;
    }
    const shapeXinPixels =  +this.props.positionX*3.78-offset;
    console.log('shapeXinPixels', shapeXinPixels);
    console.log('offset', offset);
    return +getPositionXCenter(this.props.boxType, this.props.boxSide) + +shapeXinPixels;
  }

  getPositionY() {
    let offset = 0;
    if (this.state.shapeType === "Rectangle") {
      offset = this.getHeight()/2;
    }
    const shapeYinPixels =  +this.props.positionY*3.78*-1-offset;
    return +getPositionYCenter(this.props.boxType, this.props.boxSide) + +shapeYinPixels;
  }

  getWidth() {
    return  +this.props.width*3.78;
  }

  getHeight() {
    return +this.props.height*3.78;
  }

  getFillColor() {
    let width = getWidth(this.props.boxType, this.props.boxSide);
    let height = getHeight(this.props.boxType, this.props.boxSide);

    let positionX = (+this.props.positionX*3.78);
    let positionY = (+this.props.positionY*3.78);
    let shapeWidth = (+this.props.width*3.78);
    let shapeHeight = (+this.props.height*3.78);

    // console.log('width', width);
    // console.log('height', height);
    // console.log('positionX', Math.abs(positionX));
    // console.log('positionY', Math.abs(positionY));
    // console.log('shapeWidth', shapeWidth);
    // console.log('shapeHeight', shapeHeight);

    if (shapeWidth < 2 || shapeHeight < 2) {
      return "red";
    } else if ((Math.abs(positionX) > (width/2)-(shapeWidth/2)) || (Math.abs(positionY) > (height/2)-(shapeHeight/2)) ){
      return "red";
    } else if (this.props.shapeColor != undefined && this.props.shapeColor){
      console.log("color", this.props.shapeColor)
      return this.props.shapeColor;
    } else {
      return "blue";
    }
  }
  prepareCoordinates() {

    let coordinates = {
        x: this.getPositionX(this.state.boxType, this.state.boxSide),
        y: this.getPositionY(this.state.boxType, this.state.boxSide),
        width: this.getWidth(this.state.boxType, this.state.boxSide),
        height: this.getHeight(this.state.boxType, this.state.boxSide)
    }
    return coordinates;
  }

  calculateTrianglePoints(coordinates) {
    const halfWidth = coordinates.width / 2;
    const halfHeight = coordinates.height / 2;

    const point1 = `${coordinates.x},${coordinates.y - halfHeight}`;
    const point2 = `${coordinates.x - halfWidth},${coordinates.y + halfHeight}`;
    const point3 = `${coordinates.x + halfWidth},${coordinates.y + halfHeight}`;

    return `${point1} ${point2} ${point3}`;
  }

  calculateStarPoints(coordinates) {
  // function calculateStarPoints(centerX, centerY, arms, outerRadius, innerRadius) {
    const centerX = coordinates.x;
    const centerY = coordinates.y;
    const arms = 5;
    const outerRadius = coordinates.height;
    const innerRadius = coordinates.width/2;

    let points = "";
    const angle = Math.PI / arms;

    for (let i = 0; i < 2 * arms; i++) {
      // Use outer or inner radius depending on what iteration we are in.
      let r = (i & 1) == 0 ? outerRadius : innerRadius;
      let currX = centerX + Math.cos(i * angle) * r;
      let currY = centerY + Math.sin(i * angle) * r;

      // Our first time we simply append the coordinates, subsequent times
      // we append a space and the coordinates
      points += (i === 0) ? `${currX},${currY}` : ` ${currX},${currY}`;
    }
    return points;
  }

  generateShape() {
    let coordinates = this.prepareCoordinates();
    if (this.state.shapeType === "Rectangle") {
      return (
        <rect key={this.props.shapeId + "-shape"} id={this.props.shapeId +"-shape"} {...coordinates} className='shape'  fill={this.getFillColor()}  stroke="#333"></rect>
      );
    } else if (this.state.shapeType === "Triangle") {
      let points = this.calculateTrianglePoints(coordinates);
      return (
        <polygon points={points} style={{fill: this.getFillColor(),strokeWidth:2}} />
      );
    } else if (this.state.shapeType === "Star") {
      let points = this.calculateStarPoints(coordinates);
      return (
        <polygon points={points} style={{fill: this.getFillColor(),strokeWidth:2}} />
      );
    }
  }

  render () {
    return (
        this.generateShape()
    );
  }
}

export default Shape;