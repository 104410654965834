import React from 'react';
import {getPositionX, getPositionY, getWidth, getHeight } from '../../box-helpers';

class BoxRectangle extends React.Component {

    state = {
        boxType: "",
        boxSide: ""
    }
    constructor(props) {
        super(props);
        this.state.boxType = props.boxType;
        this.state.boxSide = props.boxSide;
    }

    prepareCoordinates() {
        let coordinates = {
            x: getPositionX(this.state.boxType, this.state.boxSide),
            y: getPositionY(this.state.boxType, this.state.boxSide),
            width: getWidth(this.state.boxType, this.state.boxSide),
            height: getHeight(this.state.boxType, this.state.boxSide)
        }
        return coordinates;
    }

    render() {

        let coordinates = this.prepareCoordinates();
        return (
            <rect key={this.props.boxSide} className="rectangle" {...coordinates} id={this.state.boxSide +"-box"} stroke="#333" ></rect>
        )
    }

}

export default BoxRectangle;