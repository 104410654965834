import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import moment from 'moment';
import {
    // Row,
    Col,
    Container,
    Input,
    FormGroup,
    // Label,
    // Form,
    Alert,
    Button,
    Collapse,
    Nav, NavItem, NavLink,
    TabContent, TabPane
} from 'reactstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HoleFormItems from '../BoxDesign/HoleFormItems';
import Hole from '../BoxDesign/Hole';
import LineFormItems from '../BoxDesign/LineFormItems';
import Line from '../BoxDesign/Line';
import OAuthService from '../Auth/OAuthService';
// import queryString from 'query-string';
import {getDefaultBoxType, getDefaultBoxSide, getBoxTypes, getDefaultShapeType } from '../../box-helpers';
import ShapeFormItems from '../BoxDesign/ShapeFormItems';
import Shape from '../BoxDesign/Shape';

class CustomerDesignForm extends Component {
    tabs = {
        holeTab: "1",
        lineTab: "2",
        shapeTab: "3"
    }
    diplayClassNames = {
        hideElement: 'd-none',
        showElement: 'd-inline-block'
    }

    validEnclosureTypes = ["125B", "1590A", "1590B", "1590BB", "1590BB2", "1590XX", "1590DD"]

    state ={
        boxDesign: null,
        boxType: getDefaultBoxType(),
        boxTypes: getBoxTypes(),
        designName: '',
        hasLid: false,
        holeLids: [],
        lineLids: [],
        isPublic: true,
        isArchived: false,
        holeInputs: [],
        lineInputs: [],
        shapeInputs: [],
        deletedCount: 0,
        maxHoleLimit: 40,
        deletedLineCount: 0,
        deletedShapeCount: 0,
        maxLineLimit: 40,
        maxShapeLimit: 40,
        success: '',
        collapse: false,
        collapseText: "Collapse",
        errors: {
            designName: '',
            boxType: '',
            holes: {},
            lines: {},
            shapes: {},
            form: ''
        },
        loading: false,
        activeTab: this.tabs.holeTab,
        showShapeButton: this.diplayClassNames.hideElement,
        showLineButton: this.diplayClassNames.hideElement,
        showHoleButton: this.diplayClassNames.showElement,
        validLineEnclosure: true,
        validShapeEnclosure: true
    }

    constructor(props) {
        super(props);
        // this.handleTypeChange = this.handleTypeChange.bind(this);
        // console.log('holes', this.props.holes)

        this.togglePublic = this.togglePublic.bind(this);
        this.hasLidChange = this.hasLidChange.bind(this);

        this.state.boxType = props.boxType;
        this.state.holeInputs = Object.keys(this.props.holes).map((key, idx) => {
            return <HoleFormItems key={`hole-items-${key}`} hole={this.props.holes[key]} idx={idx+1} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />
        });
        this.state.lineInputs = Object.keys(this.props.lines).map((key, idx) => {
            return <LineFormItems key={`line-items-${key}`} line={this.props.lines[key]} idx={idx+1} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem}  hasLidChange={this.hasLidChange} />
        });
        this.state.shapeInputs = Object.keys(this.props.shapes).map((key, idx) => {
            return <ShapeFormItems key={`shape-items-${key}`} shape={this.props.shapes[key]} idx={idx+1} addShape={this.props.addShape} resetShapes={this.props.resetShapes} removeShapeFormItem={this.removeShapeFormItem}  hasLidChange={this.hasLidChange} />
        });
        this.os = new OAuthService();
    }

    componentDidMount = () => {

        const path = window.location.pathname.split("/")
        const parsed = queryString.parse(window.location.search);
        let duplicate = false;
        switch(path[path.length-1]){
            case "new":
                if(parsed["id"]){
                    duplicate = true;
                    this.setState({
                        loading: true
                    }, () => {
                        this.getBoxDesign(parsed.id, duplicate)
                    })
                }else if(parsed["public_key"]){
                    this.setState({
                        loading: true
                    }, () => {
                        this.getBoxDesign_wPublicKey(parsed.public_key)
                    })
                } else {
                    this.addDefaultHoleFormItem()
                }
                break;
            case "edit":
                if(parsed["id"]){
                    duplicate = false;
                    this.setState({
                        loading: true
                    }, () => {
                        this.getBoxDesign(parsed.id, duplicate)
                    })
                }
                break;
            default:
                break;
        }

        /*
        if(parsed["id"]){
            this.setState({
                loading: true
            }, () => {
                this.getBoxDesign(parsed.id)
            })
        } else if(parsed["public_key"]){
            this.setState({
                loading: true
            }, () => {
                this.getBoxDesign_wPublicKey(parsed.public_key)
            })
        }
        */
    }

    getBoxDesign = async (id, duplicate) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_designs/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
        //console.log(result);
        if(result["box_design"]){
            if(duplicate === false){
                this.setState({
                    boxDesign: result.box_design
                }, () => {
                    this.setBoxDesign()
                })
            }else{
                let box_design = result.box_design
                this.setState({
                    holeInputs: box_design.box_design_holes.length ? [] : this.state.holeInputs,
                    lineInputs: box_design.box_design_lines.length ? [] : this.state.lineInputs,
                    shapeInputs: box_design.box_design_shapes !== undefined && box_design.box_design_shapes.length ? [] : this.state.shapeInputs,
                    boxType: box_design.enclosure_type,
                    loading: false
                }, () => {
                    this.generateHoles(box_design)
                    this.generateLines(box_design)
                    this.generateShapes(box_design)
                });
            }
            if (this.state.boxDesign && this.state.boxDesign.box_design_lines.length > 0 && this.state.boxDesign.box_design_holes.length === 0) {
                this.setState({
                    activeTab: "2"
                });
                this.handleButtonToggle("2")
            }
        }else{
            this.setState({
                loading: false
            }, () => {
                if(result["message"]) alert(result.message);
                const history = createBrowserHistory();
                history.push({ pathname: "/box-designs/new" })
                window.location.reload();
            })
        }
    }

    getBoxDesign_wPublicKey = async (public_key) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_designs/new?public_key=' + public_key, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
        //console.log(result);
        if(result["box_design"]){
            let box_design = result.box_design
            this.setState({
                holeInputs: box_design.box_design_holes.length ? [] : this.state.holeInputs,
                boxType: box_design.enclosure_type,
                loading: false
            }, () => {
                this.generateHoles(box_design)
                this.generateLines(box_design)
                this.generateShapes(box_design)
            });

        }else{
            this.setState({
                loading: false
            }, () => {
                if(result["message"]) alert(result.message);
                const history = createBrowserHistory();
                history.push({ pathname: "/box-designs/new" })
                window.location.reload();
            })
        }
    }

    setBoxDesign = () => {
        let box_design = JSON.parse(JSON.stringify(this.state.boxDesign));

        if (box_design.active_box_job === true) {
            alert("There is currently a Box Job In progress and this design cannot be edited.");
            const history = createBrowserHistory();
            history.push({ pathname: "/dashboard" })
            window.location.reload();
        }

        this.setState({
            holeInputs: box_design.box_design_holes.length ? [] : this.state.holeInputs,
            lineInputs: box_design.box_design_lines.length ? [] : this.state.lineInputs,
            shapeInputs: box_design.box_design_shapes !== undefined && box_design.box_design_shapes.length ? [] : this.state.shapeInputs,
            designName: box_design.name,
            boxType: box_design.enclosure_type,
            isPublic: box_design.is_public === 0 ? false : true,
            isArchived: box_design.is_archived === 0 ? false : true,
            loading: false,
            validLineEnclosure: box_design.is_valid_line_enclosure
        }, () => {
            this.generateHoles(box_design)
            this.generateLines(box_design)
            this.generateShapes(box_design)
        });
    }

    generateHoles = async (box_design) => {
        if(box_design.box_design_holes.length > 0){
            await this.props.resetAllHoles();

            let arr = [];
            let hasLid = false;
            box_design.box_design_holes.forEach((a, index) => {
                const holeId = index + 1;
                const hole = <Hole key={`hole${holeId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} holeId={`hole${holeId}`} diameter={a.diameter} positionX={a.position_x} positionY={a.position_y} />
                arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

                this.props.addHole(hole);
                if (a.box_side === "Lid") {
                    hasLid = true;
                }
            })
            this.setState({ holeInputs: arr, hasLid: hasLid })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }

    removeHoleFormItem = (holeFormKey) => {
        var array = [...this.state.holeInputs];
        // console.log('hfk', holeFormKey);
        let deletedInputs = this.state.deletedCount;
        let newholeInputs = array.filter((a) => a.props.holeId !== holeFormKey)
        this.setState({
            holeInputs: newholeInputs,
            deletedCount: +deletedInputs+1
        })
    }

    addHoleFormItems = () => {
        const holeInputs = {...this.state.holeInputs };
        const boxType = this.state.boxType;
        let deletedCount = this.state.deletedCount;
        const arr = Object.keys(holeInputs).map((key) => {
            if (key) {
                return holeInputs[key];
            }
        });
        const key = Object.keys(holeInputs).length;
        const holeId = (key + 1) + deletedCount;
        const hole = <Hole key={`hole${holeId}`} boxType={boxType} boxSide={getDefaultBoxSide()} holeId={`hole${holeId}`} diameter="3" positionX="0" positionY="0" />
        arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

        this.setState({ holeInputs: arr })
        this.props.addHole(hole);
    }

    addDefaultHoleFormItem = () => {
        let holeId = 1;
        let arr = []
        const hole = <Hole key={`hole${holeId}`} boxType={getDefaultBoxType()} boxSide={getDefaultBoxSide()} holeId={`hole${holeId}`} diameter="3" positionX="0" positionY="0" />
        arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

        this.setState({ holeInputs: arr })
        this.props.addHole(hole);
    }

    generateLines = async (box_design) => {
        if(box_design.box_design_lines.length > 0){
            await this.props.resetAllLines();

            let arr = [];
            let hasLid = false;
            box_design.box_design_lines.forEach((a, index) => {
                const lineId = index + 1;
                const line = <Line key={`line${lineId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} lineId={`line${lineId}`} positionX1={a.position_x1} positionY1={a.position_y1} positionX2={a.position_x2} positionY2={a.position_y2} />
                arr.push(<LineFormItems key={`line-items-${lineId}`} line={ line } idx={lineId} lineId={`line${lineId}`} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem}  hasLidChange={this.hasLidChange} />)

                this.props.addLine(line);
                if (a.box_side === "Lid") {
                    hasLid = true;
                }
            })
            this.setState({ lineInputs: arr, hasLid: hasLid })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }

    removeLineFormItem = (lineFormKey) => {
        var array = [...this.state.lineInputs];
        // console.log('lfk', lineFormKey);
        // console.log('arr', array);
        let deletedInputs = this.state.deletedLineCount;
        let newLineInputs = array.filter((a) => a.props.lineId !== lineFormKey)
        this.setState({
            lineInputs: newLineInputs,
            deletedLineCount: +deletedInputs+1
        })
    }

    addLineFormItems = () => {
        const lineInputs = {...this.state.lineInputs };
        const boxType = this.state.boxType;
        let deletedLineCount = this.state.deletedLineCount;
        const arr = Object.keys(lineInputs).map((key) => {
            if (key) {
                return lineInputs[key];
            }
        });
        const key = Object.keys(lineInputs).length;
        const lineId = (key + 1) + deletedLineCount;
        const line = <Line key={`line${lineId}`} boxType={boxType} boxSide={getDefaultBoxSide()} lineId={`line${lineId}`}  positionX1="-10" positionY1="0"  positionX2="10" positionY2="0" />
        arr.push(<LineFormItems key={`line-items-${lineId}`} line={ line } idx={lineId} lineId={`line${lineId}`} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem}  hasLidChange={this.hasLidChange} />)

        this.setState({ lineInputs: arr })
        this.props.addLine(line);
    }

    generateShapes = async (box_design) => {
        if(box_design.box_design_shapes !== undefined && box_design.box_design_shapes.length > 0){
            await this.props.resetAllShapes();

            let arr = [];
            let hasLid = false;
            box_design.box_design_shapes.forEach((a, index) => {
                const shapeId = index + 1;
                // console.log('a', a);
                const shape = <Shape key={`shape${shapeId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} shapeId={`shape${shapeId}`}  shapeType={a.shape_type} positionX={a.position_x} positionY={a.position_y} width={a.width} height={a.height} />
                arr.push(<ShapeFormItems key={`shape-items-${shapeId}`} shape={ shape } idx={shapeId} shapeId={`shape${shapeId}`} addShape={this.props.addShape} resetShapes={this.props.resetShapes} removeShapeFormItem={this.removeShapeFormItem}  hasLidChange={this.hasLidChange} />)

                this.props.addShape(shape);
                if (a.box_side === "Lid") {
                    hasLid = true;
                }
            })
            this.setState({ shapeInputs: arr, hasLid: hasLid })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }
    removeShapeFormItem = (shapeFormKey) => {
        var array = [...this.state.shapeInputs];
        // console.log('lfk', lineFormKey);
        // console.log('arr', array);
        let deletedInputs = this.state.deletedShapeCount;
        let newShapeInputs = array.filter((a) => a.props.shapeId !== shapeFormKey)
        this.setState({
            shapeInputs: newShapeInputs,
            deletedShapeCount: +deletedInputs+1
        })
    }

    addShapeFormItems = () => {
        const shapeInputs = {...this.state.shapeInputs };
        const boxType = this.state.boxType;
        let deletedShapeCount = this.state.deletedShapeCount;
        const arr = Object.keys(shapeInputs).map((key) => {
            if (key) {
                return shapeInputs[key];
            }
        });
        // console.log('arr', shapeInputs)
        const key = Object.keys(shapeInputs).length;
        const shapeId = (key + 1) + deletedShapeCount;
        const shape = <Shape key={`shape${shapeId}`} boxType={boxType} boxSide={getDefaultBoxSide()} shapeType={getDefaultShapeType()} shapeId={`shape${shapeId}`}  positionX="0" positionY="0"  width="10" height="10" />
        arr.push(<ShapeFormItems key={`shape-items-${shapeId}`} shape={ shape } idx={shapeId} shapeId={`shape${shapeId}`} addShape={this.props.addShape} resetShapes={this.props.resetShapes} removeShapeFormItem={this.removeShapeFormItem}  hasLidChange={this.hasLidChange} />)

        this.setState({ shapeInputs: arr })
        this.props.addShape(shape);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let msg = this.validateFormValues(e.target);
        let errors = this.state.errors

        let isValid = this.validateForm(this.state.errors)

        if(isValid && msg === '') {
            errors.form = false
            this.setState({errors})
            const holeInputs = {...this.state.holeInputs }
            const lineInputs = {...this.state.lineInputs }
            const shapeInputs = {...this.state.shapeInputs }

            const holes = Object.keys(holeInputs).map((key, idx) => {
                let keyIdentifier = holeInputs[key].props.idx;
                // console.log('holeInputs', keyIdentifier);

                let holeSide = 'holeSide'+ keyIdentifier;
                let holeDiameter = 'holeDiameter'+ keyIdentifier;
                let holePositionX = 'holePositionX'+ keyIdentifier;
                let holePositionY = 'holePositionY'+ keyIdentifier;
                // Add this to validate no removed input in state
                if (e.target[holeSide] && e.target[holeDiameter] && e.target[holePositionX] && e.target[holePositionY]) {
                    //console.log('key', key);
                    return {
                        box_side: e.target[holeSide].value,
                        diameter: e.target[holeDiameter].value,
                        positionX: e.target[holePositionX].value,
                        positionY: e.target[holePositionY].value,
                    }
                }
            });
            const lines = Object.keys(lineInputs).map((key, idx) => {
                let keyIdentifier = lineInputs[key].props.idx;
                // console.log('holeInputs', keyIdentifier);

                let lineSide = 'lineSide'+ keyIdentifier;
                let linePositionX1 = 'linePositionX1'+ keyIdentifier;
                let linePositionY1 = 'linePositionY1'+ keyIdentifier;
                let linePositionX2 = 'linePositionX2'+ keyIdentifier;
                let linePositionY2 = 'linePositionY2'+ keyIdentifier;
                // Add this to validate no removed input in state
                if (e.target[lineSide] && e.target[linePositionX1] && e.target[linePositionY1] && e.target[linePositionX2] && e.target[linePositionY2]) {
                    //console.log('key', key);
                    return {
                        box_side: e.target[lineSide].value,
                        positionX1: e.target[linePositionX1].value,
                        positionY1: e.target[linePositionY1].value,
                        positionX2: e.target[linePositionX2].value,
                        positionY2: e.target[linePositionY2].value,
                    }
                }
            });
            const shapes = Object.keys(shapeInputs).map((key, idx) => {
                let keyIdentifier = shapeInputs[key].props.idx;

                let shapeSide = 'shapeSide'+ keyIdentifier;
                let shapeType = 'shapeType'+ keyIdentifier;
                let shapePositionX = 'shapePositionX'+ keyIdentifier;
                let shapePositionY = 'shapePositionY'+ keyIdentifier;
                let shapeHeight = 'shapeHeight'+ keyIdentifier;
                let shapeWidth = 'shapeWidth'+ keyIdentifier;

                // Add this to validate no removed input in state
                if (e.target[shapeSide] && e.target[shapePositionX] && e.target[shapePositionY] && e.target[shapeHeight] && e.target[shapeWidth]) {
                    //console.log('key', key);
                    return {
                        box_side: e.target[shapeSide].value,
                        shape_type: e.target[shapeType].value,
                        positionX: e.target[shapePositionX].value,
                        positionY: e.target[shapePositionY].value,
                        height: e.target[shapeHeight].value,
                        width: e.target[shapeWidth].value,
                    }
                }
            });

            let bodyParams = JSON.stringify({
                name: e.target.designName.value.trim(),
                enclosure_type: e.target.boxType.value,
                is_public: e.target.isPublic.checked ? 1 : 0,
                is_archived: this.state.boxDesign ? e.target.isArchived.checked ? 1 : 0 : 0,
                holes: holes,
                lines: lines,
                shapes: shapes
            });

            this.setState({
                loading: true
            }, async () => {
                let token = JSON.parse(localStorage.getItem("token"));
                var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_designs' + (this.state.boxDesign !== null ? '/' + this.state.boxDesign.id : ''), {
                    method: this.state.boxDesign !== null ? 'PUT' : 'POST',
                    headers: {
                        Authorization: "Bearer " + token,
                        'Content-Type': 'application/json'
                    },
                    body: bodyParams
                })
                .then(response => response.json())
                .then(data => {
                    return data;
                })
                .catch((error) => {
                    return error;
                });
                console.log(result);
                this.setState({
                    loading: false
                }, () => {
                    if(result["box_design"]){
                        toast.success('Drill design successfully saved.');
                        if(this.state.boxDesign === null){
                            const history = createBrowserHistory();
                            history.push({ pathname: "/box-designs/edit?id=" + result["box_design"].id })
                        }
                        this.setState({
                            boxDesign: result["box_design"]
                        })
                    } else {
                        if(result["message"]) alert(result.message);
                    }
                })

                // this.processResult(result);
            })

            // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
            // fetch(getApiUrl(), requestOptions)
            //     .then(this.handleErrors)
            //     .then(data => {
            //         if (this.state.errors.form !== "" && !data.message.includes('Success')) {
            //             errors.form = data.message;
            //             this.setState({  errors })
            //         } else {
            //             window.scrollTo(0, 0)
            //             this.setState({ success: data.message })
            //         }
            //     }).catch(function(error) {
            //         console.log(error);
            //     });
        }
    }

    handleErrors = (response) => {
        let errors = this.state.errors
        if (!response.ok) {
            errors.form = "ERROR"
            this.setState({errors})
            // throw Error(response.statusText);
        }
        return response.json();
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateFormValues = (formObj) => {
        let msg = '';
        if (formObj.designName.value.trim() === '') {
            msg = 'Please add a name to your design';
        } else {
            let diameterInput = 1;
            let yPosition = 1;
            let xPosition = 1;
            for (let i = 0; i < formObj.length; i++) {
                if (formObj.elements[i].getAttribute("name") === "holeDiameter"+diameterInput) {
                    // console.log("fo" + diameterInput, formObj.elements[i].getAttribute("name"))
                    if (!formObj.elements[i].value || formObj.elements[i].value < 3) {
                        msg = "Minimum hole diameter is 3mm. Make sure the hole diameter inputs have a value."
                    }
                    diameterInput += 1;
                } else if (formObj.elements[i].getAttribute("name") === "holePositionY"+yPosition) {
                    // console.log("fo" + diameterInput, formObj.elements[i].getAttribute("name"))
                    if (!formObj.elements[i].value) {
                        msg = "Y Position #" + yPosition + " must have a value."
                    }
                    yPosition += 1;
                } else if (formObj.elements[i].getAttribute("name") === "holePositionX"+xPosition) {
                    // console.log("fo" + diameterInput, formObj.elements[i].getAttribute("name"))
                    if (!formObj.elements[i].value) {
                        msg = "X Position #" + xPosition + " must have a value."
                    }
                    xPosition += 1;
                } else if (formObj.elements[i].getAttribute("name") === "boxType") {
                    if (this.invalidLineSubmit(formObj.elements[i].value) === true) {
                        msg = "Lines are currently only supported for " + this.lineEnclosureStr() + " enclosures. Please remove all lines or change enclosure type."
                    }
                    xPosition += 1;
                }
            }
            // console.log('HINPUTS', this.state.holeInputs);
        }
        let errors = this.state.errors
        errors.form = msg
        this.setState({errors})

        return msg;
    }

    validateFormFields = (name, value) => {
        let errors = this.state.errors;
        switch (name) {

            case 'designName':
                errors.designName = '';
                break;

            default:
                break;
        }

        return errors;
    }

    handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);
            this.setState({errors, [name]: value})
        }
    }


    handleSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            this.setState({[name]: value, [name+"label"]: label})
        }
    }

    handleTypeChange = (e) => {
        if (e) {
            // console.log(e);
            const {name, value, label} = e;
            // console.log(name);
            let isValid = this.checkLineValid(value);
            // console.log('isValid', isValid)
            if (isValid !== true) {
                let linesSelected = this.invalidLineSubmit(value)
                // console.log('linesSe', linesSelected)
                if (linesSelected === true){
                    alert("You have selected an enclosure type that does not work with line tool. Please select types "+ this.lineEnclosureStr() + " to use the line tool." );
                    this.toggleTab('1');
                }
            }
            this.setState({boxType: e.value, [name]: value , [name+"label"]: label, validLineEnclosure: isValid});
            this.props.handleBoxTypeChangeSelect(e.value);
        }
    }
    hasLidChange = (cut_id, box_side, cut_type) => {
        let hasLid = false;
        let holeLids = this.state.holeLids;
        let lineLids = this.state.lineLids;
        if (cut_type === "hole") {
            holeLids = holeLids.filter((e) => e !== cut_id);
        } else if (cut_type === "line") {
            lineLids = lineLids.filter((e) => e !== cut_id);
        }
        if (holeLids.length > 0 || lineLids.length > 0) {
            hasLid = true;
        } else {
            if (box_side === "Lid") {
                if (cut_type === "hole") {
                    holeLids.push(cut_id);
                }
                if (cut_type === "line") {
                    lineLids.push(cut_id);
                }
                hasLid = true;
            }
        }
        this.setState({
            hasLid: hasLid,
            holeLids: holeLids
        })
    }
    checkLineValid = (val) => {
        return this.validEnclosureTypes.indexOf(val) >= 0;
    }
    invalidLineSubmit = (val) => {
        let isValid = this.checkLineValid(val);
        return isValid !== true && (this.state.activeTab === "2" || this.state.lineInputs.length > 0)
    }
    lineEnclosureStr = () => {
        return this.validEnclosureTypes.join(" and ");
    }
    handleButtonToggle = (tab) => {
        let showHoleButton = tab === this.tabs.holeTab ? this.diplayClassNames.showElement : this.diplayClassNames.hideElement;
        let showLineButton = tab === this.tabs.lineTab ? this.diplayClassNames.showElement : this.diplayClassNames.hideElement;
        let showShapeButton = tab === this.tabs.shapeTab ? this.diplayClassNames.showElement : this.diplayClassNames.hideElement;
        this.setState({
            showHoleButton: showHoleButton,
            showLineButton: showLineButton,
            showShapeButton: showShapeButton
        });
    }
    toggle = (e) => {
        if (this.state.collapse === false) {
            this.setState({ collapse: true, collapseText: "Expand"})
        } else {
            this.setState({ collapse: false, collapseText: "Collapse"})
        }
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            this.handleButtonToggle(tab);
        }
    }
    togglePublic = (e) => {
        this.setState({
            isPublic: !this.state.isPublic
        })
    }

    toggleArchived = (e) => {
        this.setState({
            isArchived: !this.state.isArchived
        })
    }

    render() {
        return (
            <Container className="m-4">
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                { this.state.loading && (
                    <div className="loading style-2">
                        <div className="loading-wheel"></div>
                        <h3 className="loading-message">THIS MIGHT TAKE A FEW MINUTES. PLEASE WAIT...</h3>
                    </div>
                )}
                <Alert color="success" isOpen={this.state.success ? true : false} className="text-center">
                    { this.state.success } <br />
                    <Button outline color="primary" size="md" onClick={() => window.location.reload()}> Add New Template</Button>
                </Alert>
                {!this.state.success && (
                    <form action="" className="add-design" onSubmit={this.handleSubmit}>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                {this.state.boxDesign === null && <h3>Add New Drill Template</h3>}
                                {this.state.boxDesign !== null && <h3>Edit Drill Template: {this.state.boxDesign.id}</h3>}
                                <label className="col-form-label">Template Name</label>
                                <Input
                                    key="designName"
                                    id="designName"
                                    name="designName"
                                    placeholder="Drill Template Name"
                                    onChange={this.handleChange}
                                    value={this.state.designName} />

                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <label className="col-form-label">Enclosure Type</label>
                                <Select
                                    key="boxType"
                                    name="boxType"
                                    isClearable={false}
                                    value={{ value: this.state.boxType, label: this.state.boxType}}
                                    onChange={this.handleTypeChange}
                                    options={this.state.boxTypes.map((t) => ({ value: t.key, label: t.key, name: "boxType" }))}
                                />
                            </Col>
                        </FormGroup>
                        <Collapse isOpen={this.state.collapse === false}>

                        </Collapse>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <Alert color="warning" className="xs-box">
                                    <small className="text-xs" style={{ fontWeight:500}}>
                                        MAKE SURE TO TAKE COORDINATES FROM CENTER OF SIDES IN MILLIMETERS.<br />
                                        PLEASE ADD 0.2MM TO DIAMETER HOLES FOR POWDER COATING THICKNESS.<br />
                                        FOR EXAMPLE IF YOU NEED 8MM DIA HOLE, INPUT 8.20. PLEASE USE "." POINT FOR DECIMALS, PLEASE DON'T USE COMMA ","  , FOR EXAMPLE 5.36 , NOT 5,36
                                    </small>
                                </Alert>
                                <Alert color="info" className="xs-box">
                                    <small className="text-xs" style={{ fontWeight:500}}>
                                        WITH {this.lineEnclosureStr()} ENCLOSURES, YOU CAN CREATE RECTANGLES, TRIANGLES OR EVEN STAR LIKE SHAPES.
                                        UNDER LINES TAB, CLICK '+ Add Line' BUTTON TO CREATE LINES AND PUT THEM TOGETHER TO MAKE ANY SHAPE YOU WANT. TRY IT, WE HOPE YOU WILL LOVE IT!
                                    </small>
                                </Alert>
                                <Nav tabs className="design-tabs">
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === '1' ? "active" : ""} onClick={() => { this.toggleTab('1'); }} >
                                            <label className="col-form-label py-0">Holes</label>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={this.state.validLineEnclosure === true ? "" : this.diplayClassNames.hideElement}>
                                        <NavLink className={this.state.activeTab === '2' ? "active" : ""} onClick={() => { this.toggleTab('2'); }} >
                                            <label className="col-form-label py-0">Lines</label>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={this.state.validShapeEnclosure === true ? "" : this.diplayClassNames.hideElement}>
                                        <NavLink className={this.state.activeTab === '3' ? "active" : ""} onClick={() => { this.toggleTab('3'); }} >
                                            <label className="col-form-label py-0">Shapes</label>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <div className="hole-form-items">
                                            {this.state.holeInputs}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="line-form-items">
                                            {this.state.lineInputs}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="shape-form-items">
                                            {this.state.shapeInputs}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="my-4">
                            <Col className="col text-left" md={4}>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="isPublic" name="isPbulic" key="isPublic" checked={this.state.isPublic} onChange={this.togglePublic} />
                                    <label className="custom-control-label" htmlFor="isPublic">Share Template</label>
                                </div>

                                {this.state.boxDesign &&
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="isArchived" name="isArchived" key="isArchived" checked={this.state.isArchived} onChange={this.toggleArchived} />
                                        <label className="custom-control-label" htmlFor="isArchived">Archive</label>
                                    </div>
                                }
                            </Col>
                            <Col className="col text-right" md={8}>
                                <div className={this.state.showShapeButton}>
                                    <Button color="info" className="btn btn-sm mx-3" id="add-shape-btn" key="add-shape-btn" onClick={this.addShapeFormItems}>+ Add Shape</Button>
                                </div>
                                <div className={this.state.showLineButton}>
                                    <Button color="info" className="btn btn-sm mx-3" id="add-line-btn" key="add-line-btn" onClick={this.addLineFormItems}>+ Add Line</Button>
                                </div>
                                <div className={this.state.showHoleButton}>
                                    <Button color="primary" className="btn btn-sm mx-3" id="add-hole-btn" key="add-hole-btn" onClick={this.addHoleFormItems}>+ Add Hole</Button>
                                </div>
                            </Col>
                        </FormGroup>
                        {this.state.holeInputs.length >= this.state.maxHoleLimit &&
                            <Alert color="warning">
                                For designs with more than {this.state.maxHoleLimit} holes, additional drill holes will have to be purchased when you place your order.
                            </Alert>
                        }
                        {this.state.lineInputs.length >= this.state.maxLineLimit &&
                            <Alert color="warning">
                                For designs with more than {this.state.maxLineLimit} lines, additional drill lines will have to be purchased when you place your order.
                            </Alert>
                        }
                        {this.state.shapeInputs.length >= this.state.maxShapeLimit &&
                            <Alert color="warning">
                                For designs with more than {this.state.maxShapeLimit} shapes, additional drill shapes will have to be purchased when you place your order.
                            </Alert>
                        }
                        {this.state.hasLid &&
                            <Alert color="warning">
                                FYI: For Lid drilling, when you place an order you will need to buy lid drilling service option in addition to normal enclosure drilling service. Lid drilling service is sold separately&nbsp;
                                <a href='https://www.taydaelectronics.com/catalogsearch/result/?q=Lid' target='_blank' rel="noopener noreferrer">here</a>.
                            </Alert>
                        }
                        {this.state.boxDesign !== null && (
                            <FormGroup row>
                                <Col className="col text-right" md={7}>
                                    <div>Created: <b>{moment(this.state.boxDesign.created_at).format("MMM DD YYYY h:mm")}</b></div>
                                </Col>
                                <Col className="col text-right" md={5}>
                                    <div>Updated: <b>{moment(this.state.boxDesign.updated_at).format("MMM DD YYYY h:mm")}</b></div>
                                </Col>
                            </FormGroup>
                        )}
                        <FormGroup row>
                            <Col className="col text-right" md={12}>
                                <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                                {this.state.boxDesign !== null && (
                                    <a href={"new?id=" + this.state.boxDesign.id} className="btn btn-secondary mr-2" title={"Duplicate Template"+ this.state.boxDesign.id}>Duplicate Template</a>
                                )}
                                <Button color="success" type="submit">Save Template</Button>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Container>
        );
    }
}

export default CustomerDesignForm;