import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./Auth/auth";
import Page from './Layout/Page';

function PublicRoute({ component: Component, ...rest }) {
  const { token, user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
          token ? (
            <Redirect to="/dashboard" />
            ) : (
            <Page title={rest.title}><Component {...props} /></Page>
          )
      }
    />
  );
}

export default PublicRoute;