import React, { Component } from "react";
import Header from "./Header";
import {
    Row,
    Col,
    Container,
    // Input,
    // FormGroup,
    // Label,
    // Form,
    // Button
} from 'reactstrap';

class NotFound extends Component {
    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row>
                        <Col className="col" lg={12} md={12} sm={12} xs={12}>
                            <h2>Page Not Found</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
export default NotFound;