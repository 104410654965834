import React, { Component } from 'react';
import Shape from './Shape';
import {
    Row,
    Col,
    Container,
    Input,
    Label
} from 'reactstrap';
import Select from 'react-select';
import {getDefaultBoxType, getDefaultBoxSide, getDefaultShapeType} from '../../box-helpers';
import Button from 'reactstrap/lib/Button';

class ShapeFormItems extends Component {
    boxSides = [
        {
            key: "A",
            label: "A (Face)"
        },
        {
            key: "B",
            label: "B (Top)"
        },
        {
            key: "C",
            label: "C (Left)"
        },
        {
            key: "D",
            label: "D (Bottom)"
        },
        {
            key: "E",
            label: "E (Right)"
        },
        {
            key: "Lid",
            label: "Lid"
        }
    ];
    shapeTypes = [
        {
            key: "Rectangle",
            label: "Rectangle"
        },
        {
            key: "Triangle",
            label: "Triangle"
        }
    ];
    state = {
        shapeId: 0,
        boxType: getDefaultBoxType(),
        boxSide: getDefaultBoxSide(),
        shapeType: getDefaultShapeType(),
        widthColor: 'input-default',
        heightColor: 'input-default',
        hasSizeError: false,
        positionX: 0,
        positionY: 0,
        width: 0,
        height: 0
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSideChange = this.handleSideChange.bind(this)
        this.handleTypeChange = this.handleTypeChange.bind(this)
        this.state.shapeId = props.shape.props.shapeId;
        this.state.boxSide = props.shape.props.boxSide;
        this.state.shapeType = props.shape.props.shapeType;
        this.state.positionX = props.shape.props.positionX;
        this.state.positionY = props.shape.props.positionY;
        this.state.width = props.shape.props.width;
        this.state.height = props.shape.props.height;
    }

    handleDelete = (e) => {
        e.preventDefault();
        console.log('delete', this.state.shapeId)
        this.props.resetShapes(this.state.shapeId);
        this.props.removeShapeFormItem(this.state.shapeId);

    }

    handleChange = (e) => {
        e.preventDefault();

        this.props.resetShapes(this.state.shapeId)

        if (e.target.dataset.type === "width" && e.target.value && e.target.value < 2) {
            this.setState({widthColor: "input-danger", hasSizeError: true})
        } else {
            this.setState({widthColor: "input-default", hasSizeError: false})
        }
        if (e.target.dataset.type === "height" && e.target.value && e.target.value < 2) {
            this.setState({heightColor: "input-danger", hasSizeError: true})
        } else {
            this.setState({heightColor: "input-default", hasSizeError: false})
        }

        let dataType= e.target.dataset.type;
        let shapeId= e.target.dataset.id;

        this.setState({ [dataType]: e.target.value }, () => {
            const shape = <Shape key={shapeId} shapeId={shapeId} {...this.state} />
            this.props.addShape(shape);
        })

    }

    handleSideChange = (e) => {

        this.props.resetShapes(this.state.shapeId)


        this.setState({boxSide: e.value}, () => {
            const shape = <Shape key={this.state.shapeId} {...this.state} />
            this.props.addShape(shape);
        })

        this.props.hasLidChange(this.state.shapeId, e.value, "shape");

    }

    handleTypeChange = (e) => {

        this.props.resetShapes(this.state.shapeId)

        this.setState({shapeType: e.value}, () => {
            const shape = <Shape key={this.state.shapeId} {...this.state} />
            // console.log("eshape", shape);
            this.props.addShape(shape);
        })

        this.props.hasLidChange(this.state.shapeId, e.value, "shape");

    }

    render()  {
       const idx = this.props.idx;
       const key = this.props.shapeKey;

       return (
        <Container key={"shape-wrapper-" + key}>

            <Row className="form-inline form-short mt-2">
                <Col className="col pr-0" md={3}>
                    <Label className="shape-label">Shape #{idx}: Side</Label>
                </Col>
                <Col className="col pr-0" md={3} style={{ minWidth: '18%'}}>
                    <Select
                        key={"shapeSide"+idx}
                        id={"shape-side-"+idx}
                        name={"shapeSide"+idx}
                        data-id={idx} data-type="boxSide"
                        isClearable={false}
                        value={{ value: this.state.boxSide, label: this.state.boxSide}}
                        onChange={this.handleSideChange}
                        options={this.boxSides.map((t) => ({ value: t.key, label: t.label }))}
                    />
                </Col>
                <Col className="col pr-0" md={2}>
                    <Label className="shape-label">Type</Label>
                </Col>
                <Col className="col pr-0" md={4}>
                    <Select
                        key={"shapeType"+idx}
                        id={"shape-type-"+idx}
                        name={"shapeType"+idx}
                        data-id={idx} data-type="shapeType"
                        isClearable={false}
                        value={{ value: this.state.shapeType, label: this.state.shapeType}}
                        onChange={this.handleTypeChange}
                        options={this.shapeTypes.map((t) => ({ value: t.key, label: t.label }))}
                    />
                </Col>
            </Row>
            <Row className="form-inline form-short mt-1">
                <Col className="col pr-0" md={3}>
                    <Label className="shape-label">Center X (mm)</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.positionX} onChange={this.handleChange}  id={"shape-xvalue-"+idx} key={"shapePositionX"+idx} name={"shapePositionX"+idx} data-type="positionX" type="number" placeholder="Center X Value" step="any" className="shape-input-field" />
                </Col>
                <Col className="col pr-0 offset-md-1" md={3}>
                    <Label className="shape-label">Height (mm)</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.height} onChange={this.handleChange}  id={"shape-height-"+idx} key={"shapeHeight"+idx} name={"shapeHeight"+idx} data-type="height"  type="number" placeholder="Height Value" step="any" className={this.state.heightColor} />
                </Col>
            </Row>
            <Row className="form-inline form-short mt-1">
                <Col className="col pr-0" md={3}>
                    <Label className="shape-label">Center Y (mm)</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.positionY} onChange={this.handleChange}  id={"shape-yvalue-"+idx} key={"shapePositionY"+idx} name={"shapePositionY"+idx} data-type="positionY"  type="number" placeholder="Center Y Value" step="any" className="shape-input-field" />
                </Col>
                <Col className="col pr-0 offset-md-1" md={3}>
                    <Label className="shape-label">Width (mm)</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.width} onChange={this.handleChange}  id={"shape-width-"+idx} key={"shapeWidth"+idx} name={"shapeWidth"+idx} data-type="width" type="number" placeholder="Width Value" step="any" className={this.state.widthColor}  />
                </Col>
                <Col className="col pr-0" md={1}>
                    <Button onClick={this.handleDelete} color="danger">x</Button>
                </Col>
            </Row>
        </Container>
       );
   }
}

export default ShapeFormItems;