import React, { useState } from 'react';
import { createBrowserHistory } from 'history';

import { AuthContext } from "./Auth/auth";
import OAuthService from './Auth/OAuthService';

import Router from './Router';

function App() {
  const existingTokens = JSON.parse(localStorage.getItem("token"));
  const [token, setToken] = useState(existingTokens);
  const [user, setUser] = useState(null);

  const setAuthToken = (data) => {
    localStorage.setItem("token", JSON.stringify(data));
    setToken(data);
  }

  const setAuthUser = (data) => {
    setUser(data);
  }

  const postLogout = () => {
    setAuthToken(null);
    setAuthUser(null);
  }

  if(token != null && user == null){
    let os = new OAuthService();
    os.getUserInfo(token).then((result) => {
      setAuthToken(token);
      setAuthUser(result);

    }).catch((error) => {
      console.log(error);
      setAuthToken(null);
      setAuthUser(null);
    })
  }

  return (
    <AuthContext.Provider value={{ token, setAuthToken: setToken, user, setAuthUser: setUser, postLogout }}>
      <Router />
    </AuthContext.Provider>
  );
}

export default App;