import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
    Row,
    Col,
    Container,
    // Input,
    // FormGroup,
    // Label,
    // Form,
    // Button
} from 'reactstrap';
// import {getDefaultBoxType} from "../../box-helpers";
import CustomerBoxJobForm from "./CustomerBoxJobForm";
import ExistingCustomerBoxJobForm from "./ExistingCustomerBoxJobForm";

class BoxJobWrapper extends Component {
    state = {
        boxType: ' ',
        orderNumber: "",
        existingBoxJobId: false
    }


    constructor(props) {
        super(props);
        this.handleBoxTypeChangeSelect = this.handleBoxTypeChangeSelect.bind(this)
        document.title = "Tayda Box Job"
        const { order_number } = props.match.params
        this.state.orderNumber = order_number;
        let ebjid  = new URLSearchParams(this.props.location.search).get("ebjid")
        this.state.existingBoxJobId = ebjid;
    }

    componentDidMount = () => {

    }

    handleBoxTypeChangeSelect = (val) => {
        // console.log('handleBoxTypeChangeSelect', val);
        if (val) {
            this.setState({ boxType: val })
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row>
                        <Col className="col pl-0" lg={{ size:6, offset:3 }} md={{ size: 12, offset:0 }} sm={12} xs={12}>
                            <div id="form-wrapper">
                                { this.state.existingBoxJobId && (
                                    <ExistingCustomerBoxJobForm handleBoxTypeChangeSelect={this.handleBoxTypeChangeSelect} boxType={this.state.boxType} boxTypes={this.boxTypes} orderNumber={this.state.orderNumber} existingBoxJobId={this.state.existingBoxJobId} />
                                )}
                                { !this.state.existingBoxJobId && (
                                    <CustomerBoxJobForm handleBoxTypeChangeSelect={this.handleBoxTypeChangeSelect} boxType={this.state.boxType} boxTypes={this.boxTypes} orderNumber={this.state.orderNumber} existingBoxJobId={this.state.existingBoxJobId} />
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default BoxJobWrapper;