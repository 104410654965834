import React, { Component } from "react";
import { createBrowserHistory } from 'history';

import ReCAPTCHA from "react-google-recaptcha";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import OAuthService from './OAuthService';

import {
    Spinner,
    Row, Col,
    Container,
    Form, FormGroup,
    Input,
    Button,
    Alert,
    Toast, ToastHeader, ToastBody
} from 'reactstrap';

class Register extends Component {
    state = {
        userName: "",
        password: "",
        password2: "",
        captchaExpired: true,
        loading: false,
        mounted: false,
    }

    constructor(props){
        super(props);
        this.oas = new OAuthService();
    }

    componentDidMount = () => {
        this.oas.getToken.then((result) => {
            // console.log(result);
            if(result["access_token"]){
                localStorage.setItem('client_token', JSON.stringify(result["access_token"]));
            }else{
                localStorage.setItem('token', "");
            }

            this.setState({
                mounted: true
            })
        });
    }

    postRegister = (e) => {
        e.preventDefault();

        if(this.state.password.trim() != this.state.password2.trim()){
            alert("Please make sure your password match.")
            return;
        }

        this.setState({
            loading: true
        }, async () => {
            var body_params = new URLSearchParams();
            body_params.append("box_customer[email]", this.state.userName.trim());
            body_params.append("box_customer[password]", this.state.password.trim());
            body_params.append("box_customer[password_confirmation]", this.state.password2.trim());

            let token = JSON.parse(localStorage.getItem("client_token"));
            var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/register', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body_params.toString()
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error;
            });

            this.processResult(result);
        })
    }

    processResult = (result) => {
        this.setState({
            userName: "",
            password: "",
            password2: "",
            loading: false
        }, () => {
            // console.log(result);
            if(result["errors"]){
                alert(result["errors"]);
            }else{
                const history = createBrowserHistory();
                history.push({ pathname: '/dashboard' })
                window.location.reload(false);
            }
        })
    }

    setUserName = (e) => {
        this.setState({ userName: e.target.value });
    }

    setPassword = (e) => {
        this.setState({ password: e.target.value });
    }

    setPassword2 = (e) => {
        this.setState({ password2: e.target.value });
    }

    onCaptchaChange = (value) => {
        if(value === null){
            this.setState({
                captchaExpired: true
            });
        }else{
            this.setState({
                captchaExpired: false
            });
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row className="m-4">
                        <Col>
                            {!this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Register
                                    </ToastHeader>
                                    <ToastBody>
                                        <div className="mb-3">
                                            <Alert color="primary" className="small-box" >
                                                <div className="text-medium">
                                                    <b>Please use same email address as your Tayda Electronics registered account to register new account here.</b>
                                                    <br /> Password doesn't have to be same.
                                                </div>
                                            </Alert>
                                        </div>
                                        <Form className="login-form m-auto" onSubmit={this.postRegister} >
                                            <FormGroup>
                                                <Input
                                                    type="email"
                                                    value={this.state.userName}
                                                    onChange={this.setUserName}
                                                    placeholder="Email"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input
                                                    type="password"
                                                    value={this.state.password}
                                                    onChange={this.setPassword}
                                                    placeholder="Password"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input
                                                    type="password"
                                                    value={this.state.password2}
                                                    onChange={this.setPassword2}
                                                    placeholder="Retype Password"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ReCAPTCHA
                                                    style={{ margin: "15px 0px 0px 10px" }}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                    onChange={this.onCaptchaChange}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Button type="submit" color="primary"
                                                    disabled={this.state.captchaExpired ? true : (this.state.userName.trim().length == 0 || this.state.password.trim().length == 0 || this.state.password2.trim().length == 0) && this.state.mounted ? true : false}
                                                    style={{width: '100%', marginTop: '10px', fontSize: '1em', padding: '2px'}}
                                                >
                                                    Register
                                                </Button>
                                                <Alert color="danger" className="small-box my-3" >
                                                    <div className="text-medium" style={{ fontWeight:500}}>
                                                        You must confirm your email after you register. TaydaElectronics orders will not appear in this tool until your account is confirmed.
                                                        If you don't receive confirmation email, please don't forget to check your spam folder.
                                                    </div>
                                                </Alert>
                                            </FormGroup>
                                        </Form>
                                        <FormGroup>
                                            <div>
                                                Already have an account? <a className="" href="/login"><u>Login Now</u></a>
                                            </div>
                                        </FormGroup>
                                    </ToastBody>
                                </Toast>
                            }
                            {this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Registering...
                                    </ToastHeader>
                                    <ToastBody>
                                        <Spinner color="primary" className="m-auto d-flex"/>
                                    </ToastBody>
                                </Toast>
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default Register;
