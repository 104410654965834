import React, { Component } from "react";
// import { createBrowserHistory } from 'history';

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import OAuthService from './OAuthService';

import {
    Spinner,
    Row, Col,
    Container,
    Form, FormGroup,
    Input,
    Button,
    Alert,
    Toast, ToastHeader, ToastBody
} from 'reactstrap';

class PasswordReset extends Component {
    state = {
        userName: "",
        loading: false,
        mounted: false,
        responseMsg: false,
    }

    constructor(props){
        super(props);
        this.oas = new OAuthService();
    }

    componentDidMount = () => {
        this.oas.getToken.then((result) => {
            // console.log(result);
            if(result["access_token"]){
                localStorage.setItem('client_token', JSON.stringify(result["access_token"]));
            }else{
                localStorage.setItem('token', "");
            }

            this.setState({
                mounted: true
            })
        });
    }

    postPasswordReset = (e) => {
        e.preventDefault();

        this.setState({
            loading: true
        }, async () => {
            var body_params = new URLSearchParams();
            body_params.append("box_customer[email]", this.state.userName.trim());

            let token = JSON.parse(localStorage.getItem("client_token"));
            var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/password', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body_params.toString()
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error;
            });

            this.processResult(result, this.state.userName);
        })
    }

    processResult = (result, userName) => {
        this.setState({
            userName: "",
            loading: false
        }, () => {
            // console.log(result);
            if(result["errors"]){
                alert(result["errors"]);
            }else{
                this.setState({responseMsg: "Password Instructions have been sent to " + userName})
            }
        })
    }

    setUserName = (e) => {
        this.setState({ userName: e.target.value });
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row className="m-4">
                        <Col>
                            {!this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Reset Password
                                    </ToastHeader>
                                    <ToastBody>
                                        <Alert color="success" className="small-box mb-3" isOpen={this.state.responseMsg ? true : false } >
                                            <div className="text-medium">
                                                { this.state.responseMsg }
                                            </div>
                                        </Alert>
                                        <Form className="login-form m-auto" onSubmit={this.postPasswordReset} >
                                            <FormGroup>
                                                <Input
                                                    type="email"
                                                    value={this.state.userName}
                                                    onChange={this.setUserName}
                                                    placeholder="Email"
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Button type="submit" color="primary"
                                                    disabled={(this.state.userName.trim().length == 0) && this.state.mounted ? true : false}
                                                    style={{width: '100%', marginTop: '10px', fontSize: '1em', padding: '2px'}}
                                                >
                                                    Reset Password
                                                </Button>
                                            </FormGroup>
                                        </Form>
                                        <FormGroup>
                                            <div className="text-center">
                                                <a className="" href="/login"><u>Login</u></a>
                                            </div>
                                        </FormGroup>
                                    </ToastBody>
                                </Toast>
                            }
                            {this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Sending...
                                    </ToastHeader>
                                    <ToastBody>
                                        <Spinner color="primary" className="m-auto d-flex"/>
                                    </ToastBody>
                                </Toast>
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default PasswordReset;