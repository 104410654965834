import React from "react";
import { Alert } from 'reactstrap';

const Footer = props => (
    <footer>
        <Alert color="default" className="footer-alert">
            <div className="text-large" style={{ fontWeight:700}}>
                This form is optimized to work with Chrome. Browsers other than Chrome may cause unexpected issues.
            </div>
        </Alert>
        <h5 style={{margin:'20px auto 0', width:'180px'}}><a href="https://www.taydaelectronics.com" target="_blank" rel="noopener noreferrer" style={{color:"#212529"}}>Tayda Electronics&reg;</a></h5>
    </footer>
);

export default Footer;