import React, { Component } from 'react';
import {
    // Row,
    Col,
    Container,
    Input,
    FormGroup,
    Label,
    FormText,
    // Form,
    Alert,
    Button,
    Collapse
} from 'reactstrap';
import Select from 'react-select';
import queryString from 'query-string';
import {getDefaultBoxType, getBoxTypes, getApiUrl } from '../../box-helpers';

class AddUploadFormV3 extends Component {

    state ={
        boxType: '',
        boxTypes: getBoxTypes(),
        skus: [],
        allEnclosures: [],
        enclosures: [],
        colorProducts: [],
        whiteProducts: [],
        glossProducts: [],
        availableQuantities: [],
        allJobs: [],
        availableJobs: [],
        orderNumber: '',
        designName: '',
        sku: '',
        skulabel: '',
        enclosureSku: '',
        enclosureSkulabel: '',
        colorSku: '',
        colorSkulabel: '',
        whiteSku: '',
        whiteSkulabel: '',
        glossSku: '',
        glossSkulabel: '',
        boxJob: '',
        boxJoblabel: '',
        color: '',
        quantity: '',
        success: '',
        collapse: false,
        glossCollapse: false,
        colorCollapse: false,
        whiteCollapse: false,
        collapseText: "Collapse",
        uploadingFile: false,
        errors: {
            orderNumber: '',
            designName: '',
            sku: '',
            color: '',
            quantity: '',
            enclosureSku: '',
            boxType: '',
            uploadFile: '',
            form: ''
        }

    }

    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        // console.log('holes', this.props.holes)
        const parsed = queryString.parse(window.location.search);
        if (parsed.orderNumber) {
            this.state.orderNumber = parsed.orderNumber
        }
        if (parsed.sku) {
            this.state.sku = parsed.sku
        }
        if (parsed.color) {
            this.state.color = parsed.color
        }
        if (parsed.quantity) {
            this.state.quantity = parsed.quantity
        }
        this.state.boxType = props.boxType;

    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    handleSubmit = (e) => {
        e.preventDefault();
        let target = e.target;
        let msg = this.validateFormValues(e.target);
        let errors = this.state.errors

        let isValid = this.validateForm(this.state.errors)

        if(isValid && msg === '') {
            errors.form = false
            this.setState({errors})

            // console.log("FILES", e.target.uploadFile.files);
            // console.log("FILESENCODED",btoa(e.target.uploadFile.files[0]))
           this.setState({uploadingFile: true})

           this.toBase64(target.uploadFile.files[0])
                .then(data => {
                    // console.log('data', data)
                    let encodedFile = data;
                    // console.log("encodedFile", encodedFile)
                    // console.log("e.target", target)
                    // send email
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            order_number: target.orderNumber.value.trim(),
                            name: target.designName.value.trim(),
                            sku: target.sku.value.trim(),
                            enclosure_sku: target.enclosureSku.value.trim(),
                            enclosure_type: target.boxType.value.trim(),
                            quantity: target.quantity.value.trim(),
                            color_layer: target.colorLayer.value.trim(),
                            color_sku: target.colorSku.value.trim(),
                            print_color_layer: (target.colorSku.value.trim() > 0 ? "Yes" : "No"),
                            white_layer: target.whiteLayer.value.trim(),
                            white_sku: target.whiteSku.value.trim(),
                            print_white_layer: (target.whiteSku.value.trim() > 0 ? "Yes" : "No"),
                            rdg_white_layer: target.rdgWhiteLayer.value.trim(),
                            gloss_layer: target.glossLayer.value.trim(),
                            gloss_sku: target.glossSku.value.trim(),
                            rdg_gloss_layer: target.rdgGlossLayer.value.trim(),
                            gloss_type: target.glossType.value.trim(),
                            drilling_job: target.boxJobId.value.trim() > 0 ? "Yes" : "No",
                            box_job_id: target.boxJobId.value.trim(),
                            upload_file: encodedFile
                        })
                    };

                    // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
                    fetch(getApiUrl(), requestOptions)
                        .then(this.handleErrors)
                        .then(data => {
                            if (this.state.errors.form !== "" && !data.message.includes('Success')) {
                                errors.form = data.message;
                                this.setState({  errors, uploadingFile: false  })
                            } else {
                                window.scrollTo(0, 0)
                                this.setState({ success: data.message, uploadingFile: false })
                            }
                            return data;
                        }).catch(function(error) {
                            console.log(error);
                            this.setState({ uploadingFile: false  })
                        });
                })

        }
    }

    handleErrors = (response) => {
        let errors = this.state.errors
        if (!response.ok) {
            errors.form = "ERROR"
            this.setState({errors, uploadingFile: false})
            // throw Error(response.statusText);
        }
        return response.json();
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateFormValues = (formObj) => {
        let msg = '';
        if (formObj.orderNumber.value.trim() === '') {
            msg = 'Order Number must be 5 characters long!';
        } else if (formObj.sku.value.trim() === '') {
            msg = 'Uv Product is required!';
        } else if (formObj.enclosureSku.value.trim() === '') {
            msg = 'Enclosure Product is required!';
        } else if (formObj.colorLayer.value.trim() === '') {
        msg = 'Color Layer answer required!';
        } else if (formObj.designName.value.trim() === '') {
            msg = 'Please add a name to your design';
        // } else if (this.state.colorCollapse === true && formObj.printColorLayer.value.trim() === '') {
        //     msg = 'Print Pass Color Layer answer required!';
        } else if (formObj.whiteLayer.value.trim() === '') {
            msg = 'White Layer answer required!';
        // } else if (this.state.whiteCollapse === true && formObj.printWhiteLayer.value.trim() === '') {
        //     msg = 'Print Pass White Layer answer required!';
        } else if (formObj.glossLayer.value.trim() === '') {
            msg = 'Gloss Layer answer required!';
        } else if (formObj.uploadFile.value === '') {
            msg = 'Design File required!';
        } else if (formObj.agreementTerms.checked != true) {
            msg = 'Please agree to terms by checking the checkbox';
        } else if (formObj.doubleCheckTerms.checked != true) {
            msg = 'Please agree to terms by  double checking your design and check the checkbox';
        } else if (formObj.sizeAgreementTerms.checked != true) {
            msg = 'Please verify that you have reviewed the sizes by checking the checkbox';
        }

        let errors = this.state.errors
        errors.form = msg
        this.setState({errors})

        return msg;
    }

    validateFormFields = (name, value) => {
        let errors = this.state.errors;
        switch (name) {
            case 'orderNumber':
                errors.orderNumber =
                value.length < 5
                    ? 'Order Number must be at least 5 characters long!'
                    : '';
                break;
            case 'colorLayer':
                errors.colorLayer = value.length == 0 ? 'Select a Color Layer' : '';
                break;
            case 'quantity':
                errors.quantity = '';
                break;
            case 'sku':
                errors.sku =
                value.length === 0
                    ? 'Uv Product required'
                    : '';
                break;
            case 'enclosureSku':
                errors.enclosureSku =
                value.length === 0
                ? 'Enclosure Product required'
                : '';
                break;
            case 'designName':
                errors.designName =
                value.length === 0
                ? 'Design Name required'
                : '';
                break;

            default:
                break;
        }
        // if (name != 'color') {
        //     errors.color = '';
        // }
        return errors;
    }

    handleOrderChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            if (value.length >8) {
            // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
            fetch(getApiUrl() + "/search?order_number="+value, requestOptions)
                .then(this.handleErrors)
                .then(data => {
                    if (this.state.errors.form !== "" && !data.message.includes('Success')) {
                        errors.form = data.message;
                        this.setState({  errors })
                    } else {
                        // console.log('data', data);
                        this.setState({ sku: "", enclosureSku: "", skulabel: "", enclosureSkulabel: "", boxJoblabel: "", glossSku: "", glossSkulabel: "",
                                        skus: [], enclosures: [], availableQuantities: [], availableJobs: [], allJobs: [] })
                        let quantity = 0
                        if (data && data.uv_products && data.uv_products.length > 0) {
                            let uv_products = data.uv_products.reduce((results, key) => {
                                if (key.available_quantity > 0) {
                                    quantity = key.available_quantity;
                                    results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        available_quantity: key.available_quantity,
                                        box_type: key.attribute_value
                                    });
                                }
                                return results;
                            }, [])
                            // console.log('uv_products', uv_products);
                            let availableQuantities = []
                            for (let i = 1; i <= quantity; i++) {
                                availableQuantities.push(i);
                            }

                            let allJobs = []
                            if (data && data.drill_box_jobs && data.drill_box_jobs.length > 0) {
                                allJobs = data.drill_box_jobs.map((key) => {
                                    return {
                                        value: key.id,
                                        label: key.qty + " " + key.name + " " + key.enclosure_type + " " + key.powder_color,
                                        qty:  key.qty,
                                        name:  key.name,
                                        enclosure_type:  key.enclosure_type,
                                        powder_color: key.powder_color
                                    }
                                })
                            }
                            if (allJobs.length === 0) {
                                allJobs = [{value: 0, label: "No Existing Jobs Found. (Optional)"}]
                            }

                            this.setState({
                                skus: uv_products,
                                availableQuantities: availableQuantities,
                                allJobs: allJobs
                            })

                        }

                        if (data && data.enclosure_products && data.enclosure_products.length > 0) {
                            let enclosure_products = data.enclosure_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_uv_quantity > 0) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_uv_quantity + " - " +key.name + " " + key.sku,
                                        box_type: key.attribute_value,
                                        available_quantity: key.available_uv_quantity,
                                        color: key.enclosure_color,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('enclosure_products', enclosure_products);
                            this.setState({ allEnclosures: enclosure_products })
                        }

                        let color_products = []
                        if (data && data.color_products && data.color_products.length > 0) {
                            color_products = data.color_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_quantity > 0 && key.available_quantity >= quantity) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        // box_type: key.attribute_value,
                                        available_quantity: key.available_quantity,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('gloss_products', gloss_products);
                        }

                        // if (color_products.length === 0) {
                        //     color_products = [{value: 0, label:  "NO ADDITIONAL SERVICE NEEDED FOR THIS LAYER."}]
                        // } else {
                            color_products.push({ value: 0, label: "NO ADDITIONAL SERVICE NEEDED FOR THIS LAYER."})
                        // }

                        let white_products = []
                        if (data && data.white_products && data.white_products.length > 0) {
                            white_products = data.white_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_quantity > 0 && key.available_quantity >= quantity) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        // box_type: key.attribute_value,
                                        available_quantity: key.available_quantity,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('gloss_products', gloss_products);
                        }

                        // if (white_products.length === 0) {
                        //     white_products = [{value: 0, label:  "There was no white service or not enough white service bought with this order."}]
                        // } else {
                            white_products.push({ value: 0, label: "NO ADDITIONAL SERVICE NEEDED FOR THIS LAYER."})
                        // }

                        let gloss_products = []
                        if (data && data.gloss_products && data.gloss_products.length > 0) {
                            gloss_products = data.gloss_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_quantity > 0 && key.available_quantity >= quantity) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        // box_type: key.attribute_value,
                                        available_quantity: key.available_quantity,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('gloss_products', gloss_products);
                        }

                        // if (gloss_products.length === 0) {
                        //     gloss_products = [{value: 0, label:  "There was no gloss service or not enough gloss service bought with this order."}]
                        // } else {
                            gloss_products.push({ value: 0, label: "NO ADDITIONAL SERVICE BOUGHT FOR THIS LAYER. (GLOSS LAYER WILL NOT PRINT)"})
                        // }

                        this.setState({ glossProducts: gloss_products, colorProducts: color_products, whiteProducts: white_products })
                    }
                }).catch(function(error) {
                    console.log(error);
                });
            }
            this.setState({errors, [name]: value})
        }
    }

    handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);
            this.setState({errors, [name]: value})
        }
    }

    handleSkuSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            let currentEnclosures = this.state.allEnclosures
            let availableEnclosures = []
            if (currentEnclosures.length > 0) {
                let isCombo = false;
                this.setState({ enclosureSku: "", enclosureSkulabel: "" })
                availableEnclosures  = currentEnclosures.reduce((result, key) => {
                    // console.log('key', key)
                    if (key && key.product_id === e.product_id) {
                        result = [{ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id }]
                        isCombo = true
                    } else if (isCombo === false && key && key.box_type === e.box_type) {
                        result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id  })
                    }
                    return result;
                }, [])
            }
            if (availableEnclosures.length === 0) {
                availableEnclosures = [{value: 0, label: "No Enclosures available"}]
            }

            this.setState({[name]: value, [name+"label"]: label, enclosures: availableEnclosures})
        }
    }

    handleSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            this.setState({[name]: value, [name+"label"]: label})
        }
    }

    handleTypeChange = (e) => {
        if (e) {
            // console.log(e);
            const {name, value, label} = e;
            if (e.available_quantity != undefined) {
                let availableQuantities = []
                for (let i = 1; i <= e.available_quantity; i++) {
                    availableQuantities.push(i);
                }
                this.setState({quantity: e.available_quantity, availableQuantities: availableQuantities})
            }

            let currentJobs = this.state.allJobs
            let availableJobs = []
            if (currentJobs.length > 0) {
                this.setState({boxJob: "", boxJoblabel: ""})
                availableJobs  = currentJobs.reduce((result, key) => {
                    if (key.enclosure_type === e.box_type && key.powder_color === e.color) {
                        // console.log('key', key)
                        result.push({ value: key.value, label: key.label })
                    }
                    return result;
                }, [])
            }
            if (availableJobs.length === 0) {
                availableJobs = [{value: 0, label: "NO DRILL JOBS AVAILABLE."}]
            } else {
                availableJobs.push({value: 0, label: "NO DRILL JOB ORDERED FOR THIS ENCLOSURE."})
            }
            // console.log('allJobs', availableJobs)

            this.setState({boxType: e.box_type, color: e.color, [name]: value , [name+"label"]: label, availableJobs: availableJobs});
            this.props.handleBoxTypeChangeSelect(e.box_type);
        }
    }

    handleColorChange = (e) => {
        if (e) {
            if (e.value == "Yes") {
                this.setState({colorCollapse: true})
            } else if (e.value == "No") {
                this.setState({colorCollapse: false})
            }
        }
    }

    handleWhiteChange = (e) => {
        if (e) {
            if (e.value == "Yes") {
                this.setState({whiteCollapse: true})
            } else if (e.value == "No") {
                this.setState({whiteCollapse: false})
            }
        }
    }

    handleGlossChange = (e) => {
        if (e) {
            if (e.value == "Yes") {
                this.setState({glossCollapse: true})
            } else if (e.value == "No") {
                this.setState({glossCollapse: false})
            }
        }
    }

    toggle = (e) => {
        if (this.state.collapse === false) {
            this.setState({ collapse: true, collapseText: "Expand"})
        } else {
            this.setState({ collapse: false, collapseText: "Collapse"})
        }
    }

    render() {
        return (
            <Container className="m-4">
                { this.state.uploadingFile && (
                    <div className="loading style-2">
                        <div className="loading-wheel"></div>
                        <h3 className="loading-message">THIS MIGHT TAKE A FEW MINUTES. PLEASE WAIT...</h3>
                    </div>
                )}
                <Alert color="success" isOpen={this.state.success ? true : false} className="text-center">
                    { this.state.success } <br />
                    <Button outline color="primary" size="md" onClick={() => window.location.reload()}> Add New Design</Button>
                </Alert>
                {!this.state.success && (
                    <form action="" className="edit-design" onSubmit={this.handleSubmit}>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <h3>Upload your UV print template</h3>
                                <Alert color="warning" className="small-box" >
                                    <div className="text-small" style={{ fontWeight:500}}>
                                        PLEASE WAIT AT LEAST 15 MINUTES AFTER PLACING YOUR ORDER TO SUBMIT THIS PAGE OTHERWISE YOU MIGHT GET AN ERROR MESSAGE.
                                    </div>
                                </Alert>
                                <label className="col-form-label">Order Number (Starts with 100xxxxxx)</label>
                                <Input key="orderNumber" id="order-number" name="orderNumber" type="number" onChange={this.handleOrderChange} placeholder="Order Number" value={this.state.orderNumber} />
                                <Alert color="warning" isOpen={this.state.errors.orderNumber ? true : false}>{this.state.errors.orderNumber}</Alert>
                            </Col>
                        </FormGroup>
                        <Collapse isOpen={this.state.collapse === false}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">UV Product</label>
                                    <Select
                                        key="sku"
                                        name="sku"
                                        isClearable={false}
                                        value={{ value: this.state.sku, label: this.state.skulabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.skus.map((t) => ({ value: t.value, label: t.label, name: "sku", box_type: t.box_type, product_id: t.product_id }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.sku ? true : false}>{this.state.errors.sku}</Alert>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Product</label>
                                    <Select
                                        key="enclosureSku"
                                        name="enclosureSku"
                                        isClearable={false}
                                        value={{ value: this.state.enclosureSku, label: this.state.enclosureSkulabel}}
                                        onChange={this.handleTypeChange}
                                        options={this.state.enclosures.map((t) => ({ value: t.value, label: t.label, name: "enclosureSku", box_type: t.box_type,  color: t.color, available_quantity: t.available_quantity }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.enclosureSku ? true : false}>{this.state.errors.enclosureSku}</Alert>
                                </Col>
                            </FormGroup>

                            {/* <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Type</label>
                                    <Select
                                        key="boxType"
                                        name="boxType"
                                        isClearable={true}
                                        value={{ value: this.state.boxType, label: this.state.boxType}}
                                        onChange={this.handleTypeChange}
                                        options={this.state.boxTypes.map((t) => ({ value: t.key, label: t.key }))}
                                    />
                                </Col>
                            </FormGroup> */}
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Type</label>
                                    <Input readOnly key="boxType" id="boxType" name="boxType" placeholder="Box Type" value={this.state.boxType} />

                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Color</label>
                                    <Input readOnly key="color" id="color" name="color" placeholder="Color" value={this.state.color} />
                                    <Alert color="default" isOpen={false}>
                                        <small className="text-xs">
                                            For plain enclosure just put "Bare" name, if your enclosure is powder coated please just put it's color name in here.
                                        </small>
                                    </Alert>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Design Name</label>
                                    <Input
                                        key="designName"
                                        id="designName"
                                        name="designName"
                                        placeholder="Uv Design Name"
                                        onChange={this.handleChange}
                                        value={this.state.designName} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Quantity</label>
                                    {/* <Input key="quantity" id="quantity" name="quantity" onChange={this.handleChange} type="number" placeholder="Quantity" value={this.state.quantity} /> */}
                                    <Select
                                        key="quantity"
                                        name="quantity"
                                        isClearable={false}
                                        value={{ value: this.state.quantity, label: this.state.quantity}}
                                        onChange={this.handleSelectChange}
                                        options={this.state.availableQuantities.map((t) => ({ value: t, label: t, name: "quantity" }))}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">DO YOU HAVE ANY DATA IN COLOR LAYER?</label>
                                    <Select
                                        key="colorLayer"
                                        name="colorLayer"
                                        onChange={this.handleColorChange}
                                        isClearable={false}
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.colorCollapse === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">DID YOU BUY ANY ADDITIONAL SERVICE FOR COLOR LAYER?</label>
                                        <Select
                                            key="colorSku"
                                            name="colorSku"
                                            isClearable={false}
                                            value={{ value: this.state.colorSku, label: this.state.colorSkulabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSku" }))}
                                        />
                                    </Col>
                                </FormGroup>
                                {/* <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">WILL YOU BE BUYING ADDITIONAL PRINT PASS FOR COLOR LAYER?</label>
                                        <Select
                                            key="printColorLayer"
                                            name="printColorLayer"
                                            isClearable={false}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                        />
                                    </Col>
                                </FormGroup> */}
                            </Collapse>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">DO YOU HAVE ANY DATA IN WHITE LAYER?</label>
                                    <Select
                                        key="whiteLayer"
                                        name="whiteLayer"
                                        onChange={this.handleWhiteChange}
                                        isClearable={false}
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.whiteCollapse === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">DID YOU APPLY RDG_WHITE COLOR FROM ROLAND SWATCH INSTEAD OF WHITE COLOR?</label>
                                        <Select
                                            key="rdgWhiteLayer"
                                            name="rdgWhiteLayer"
                                            isClearable={false}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No (white layer will not print)"}]}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">DID YOU BUY ANY ADDITIONAL SERVICE FOR WHITE LAYER?</label>
                                        <Select
                                            key="whiteSku"
                                            name="whiteSku"
                                            isClearable={false}
                                            value={{ value: this.state.whiteSku, label: this.state.whiteSkulabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSku" }))}
                                        />
                                    </Col>
                                </FormGroup>
                                {/* <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">WILL YOU BE BUYING ADDITIONAL PRINT PASS FOR WHITE LAYER?</label>
                                        <Select
                                            key="printWhiteLayer"
                                            name="printWhiteLayer"
                                            isClearable={false}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                        />
                                    </Col>
                                </FormGroup> */}
                            </Collapse>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">DO YOU HAVE ANY DATA IN GLOSS LAYER?</label>
                                    <Select
                                        key="glossLayer"
                                        name="glossLayer"
                                        isClearable={false}
                                        onChange={this.handleGlossChange}
                                        defaultValue="No"
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.glossCollapse === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                        <Select
                                            key="glossSku"
                                            name="glossSku"
                                            isClearable={false}
                                            value={{ value: this.state.glossSku, label: this.state.glossSkulabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSku" }))}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">DID YOU APPLY RDG_GLOSS COLOR FROM ROLAND SWATCH TO YOUR GLOSS LAYER?</label>
                                        <Select
                                            key="rdgGlossLayer"
                                            name="rdgGlossLayer"
                                            isClearable={false}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No  (gloss layer will not print)"}]}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">PLEASE SELECT GLOSS PRINT TYPE </label>
                                        <Select
                                            key="glossType"
                                            name="glossType"
                                            isClearable={false}
                                            options={[{ value: "Varnish", label: "VARNISH" }, { value: "Matte" , label: "MATTE"}]}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            {/* <FormGroup row>
                                <Col className="col hide " md={12}>
                                    <label className="col-form-label">DO YOU HAVE DRILLING JOB ORDER FOR THIS TEMPLATE ALSO?</label>
                                    <Select
                                        key="drillingJob"
                                        name="drillingJob"
                                        isClearable={false}
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />

                                </Col>
                            </FormGroup> */}
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">IF YOU ORDERED A DRILL JOB FOR THIS ENCLOSURE, PLEASE SELECT THE DRILL JOB NAME</label>

                                    <Select
                                        key="boxJobId"
                                        name="boxJobId"
                                        isClearable={false}
                                        value={{ value: this.state.boxJob, label: this.state.boxJoblabel}}
                                        onChange={this.handleSelectChange}
                                        options={this.state.availableJobs.map((t) => ({ value: t.value, label: t.label, name: "boxJob" }))}
                                    />
                                </Col>
                            </FormGroup>


                        </Collapse>
                        <FormGroup row>
                            <Col className="col" md={12}>

                                <Label for="uploadFile">Upload File</Label>
                                <Input type="file" name="uploadFile" id="uploadFile" />
                                <FormText color="muted">
                                    ONLY PDF FORMAT FILE PLEASE
                                </FormText>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-warning" md={12}>
                                <Label for="agreementTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="agreementTerms" falsevalue="true" />
                                    Yes, I carefully read instructions in Tayda site and prepared my file carefully. I understand that Tayda can't edit my file even if there is something wrong with it visibly and Tayda will print the file as it is. Instructions Page: <a href="https://www.taydaelectronics.com/hardware/enclosures/enclosure-uv-printing-service.html" title="Tayda UV Instructions" target="_blank"  rel="noopener noreferrer">https://www.taydaelectronics.com/hardware/enclosures/enclosure-uv-printing-service.html</a>
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-danger" md={12}>
                                <Label for="doubleCheckTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="doubleCheckTerms" falsevalue="true" />
                                    I double checked and I confirm that everything in this form is correct and I understand that no matter what I won't be able to request or change anything about this order after I click Submit Order button.
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-info" md={12}>
                                <Label for="sizeAgreementTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="sizeAgreementTerms" falsevalue="true" />
                                    I made sure my file size is correct. (For 125B enclosure 62x117mm , 1590B enclosure 56x108.50mm, 1590A enclosure 35x89mm, 1590BB enclosure 90x115.50mm, 1590XX enclosure 117x141mm, 1590DD enclosure 117x185mm.)
                                    We often have received the wrong artboard / artwork size files and this can delay your UV print job by weeks, or even a month and print result can look very bad. Please double check properties of your pdf file to see it is correct size.
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col text-right" md={12}>
                                <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                                <Button color="success" type="submit">Submit Order</Button>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Container>
        );
    }
}

export default AddUploadFormV3;