import React, { Component } from "react";
// import { createBrowserHistory } from 'history';
import { humanize } from '../../box-helpers'

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import OAuthService from './OAuthService';

import {
    Spinner,
    Row, Col,
    Container,
    Alert,
    Toast, ToastHeader, ToastBody
} from 'reactstrap';

class AccountConfirmation extends Component {
    state = {
        confirmationToken: "",
        loading: false,
        mounted: false,
        responseMsg: "",
        errorMsg: "",
    }

    constructor(props){
        super(props);
        this.oas = new OAuthService();
    }

    componentDidMount = () => {
        this.oas.getToken.then((result) => {
            // console.log(result);
            if(result["access_token"]){
                localStorage.setItem('client_token', JSON.stringify(result["access_token"]));
            }else{
                localStorage.setItem('token', "");
            }

            this.setState({
                mounted: true
            })
        });
        let confirmationToken = new URLSearchParams(this.props.location.search).get("confirmation_token");

        if (confirmationToken) {
            this.setState({confirmationToken: confirmationToken})
            this.postConfirmation();
        }
    }

    postConfirmation = () => {
        // e.preventDefault();

        this.setState({
            loading: true
        }, async () => {
            // var body_params = new URLSearchParams();
            // body_params.append("box_customer[confirmation_token]", this.state.confirmationToken.trim());

            let token = JSON.parse(localStorage.getItem("client_token"));
            var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/confirmation?confirmation_token='+this.state.confirmationToken.trim(), {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                // body: body_params.toString()
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error;
            });

            this.processResult(result);
        })
    }

    processResult = (result) => {
        this.setState({
            userName: "",
            loading: false,
            responseMsg: "",
            errorMsg: "",
        }, () => {
            // console.log(result);
            if(result["errors"]){
                console.log('result error: ',result["errors"]);
                let errors = Object.keys(result["errors"]).map(key => {
                    let human_key = humanize(key);
                    return  human_key + " " + result["errors"][key];
                })
                // alert(errors);
                this.setState({ alertMsg: errors})
            }else{
                this.setState({responseMsg: "Account has been confirmed."})
            }
        })
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row className="m-4">
                        <Col>
                            {!this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Account Confirmation
                                    </ToastHeader>
                                    <ToastBody>
                                        <Alert color="success" className="small-box mb-3" isOpen={this.state.responseMsg ? true : false } >
                                            <div className="text-medium">
                                                { this.state.responseMsg }
                                                <div>
                                                    <a className="" href="/login"><u>Login</u></a>
                                                </div>
                                            </div>
                                        </Alert>
                                        <Alert color="danger" className="small-box mb-3 text-center" isOpen={this.state.alertMsg && !this.state.responseMsg ? true : false } >
                                            <div className="text-medium">
                                                { this.state.alertMsg }
                                                <div>
                                                    <a className="" href="/login"><u>Login</u></a>
                                                </div>
                                            </div>
                                        </Alert>
                                    </ToastBody>
                                </Toast>
                            }
                            {this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Sending...
                                    </ToastHeader>
                                    <ToastBody>
                                        <Spinner color="primary" className="m-auto d-flex"/>
                                    </ToastBody>
                                </Toast>
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default AccountConfirmation;