import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../Auth/auth";

const Header = props => {
    const { user, postLogout } = useAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    function logout(){
        postLogout();
    }

    return(
        <header className="top">
            {/* <div className={user != null ? "float-right py-3" : "d-none"}> */}
            <div className={user != null ? "float-right pr-4 py-2_5" : "d-none"}>
                <Dropdown className="btn btn-secondary p-0" isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret >
                        <span style={{fontSize: '0.8em'}}>
                            {user != null ? user["email"] : ""}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag={Link} to="/dashboard">Dashboard</DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={logout}>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            {/* <div className={user != null ? "float-right py-3" : "d-none"}> */}
            <div className="float-right py-3 mr-3">

                <Nav>
                    {/* <NavItem>
                        <NavLink className="mr-3 nav-links" href="/design">Create Drill Template &nbsp;&nbsp; | </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="mr-3 nav-links" href="/upload">Upload UV Print Template &nbsp;&nbsp; | </NavLink>
                    </NavItem> */}
                    <NavItem>
                        <NavLink className="mr-3 nav-links" href="/design-experiment">Experiment with Drill Tool</NavLink>
                    </NavItem>
                </Nav>

            </div>
            <h1 className="header-title px-4 py-2">
                <a href="/">Tayda Box Tool</a>
                <span className="tagline">
                &nbsp;&nbsp;&nbsp;&nbsp;By <a href="https://www.taydaelectronics.com/hardware/enclosures.html" target="_blank" rel="noopener noreferrer" title="Tayda Electronics" >Tayda Electronics&reg;</a>
                </span>
            </h1>

        </header>
    );
};


export default Header;