import React from 'react';
import BoxRectangle from './BoxRectangle';
import BoxLabel from './BoxLabel';
import Hole from './Hole';
import AddDesignFormV3 from './AddDesignFormV3';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

import {
    Row,
    Col,
    Container,
    // Input,
    // FormGroup,
    // Label,
    // Form,
    // Button
} from 'reactstrap';
import queryString from 'query-string';
import {getDefaultBoxType, getDefaultBoxSide } from '../../box-helpers';

// 125B Viewport 515.97 x 723.87
// SIDE A CENTER 66.50 x 121.50mm  - 251.37 x 459.27
// SIDE B TOP 66.50 x 35mm - 251.37 x 132.3
// SIDE C LEFT 35 x 121.50mm  - 132.3 x 459.27
// SIDE D BOTTOM 66.50 x 35mm - 251.37 x 132.3
// SIDE E RIGHT 35 x 121.50mm - 132.3 x 459.27

class BoxWrapperV3 extends React.Component {
    holes = {
        hole1: {
            positionX: 0,
            positionY: 0,
            diameter: 3,
            boxSide: getDefaultBoxSide(),
            holeId: "hole1"
        },
        // hole1: {
        //     positionX: -15,
        //     positionY: 5,
        //     diameter: 2.55,
        //     boxSide: "A"
        // },
        // hole2: {
        //     positionX: 15,
        //     positionY: 5,
        //     diameter: 2.55,
        //     boxSide: "A"
        // }
    }
    state = {
        boxType: getDefaultBoxType(),
        holes: [],
        boxA: {},
        boxB: {},
        boxC: {},
        boxD: {},
        boxE: {},
        boxLid: {},
        labelA: {},
        labelB: {},
        labelC: {},
        labelD: {},
        labelE: {},
        labelLid: {},
    }


    constructor(props) {
        super(props);
        this.handleBoxTypeChangeSelect = this.handleBoxTypeChangeSelect.bind(this)
        const parsed = queryString.parse(window.location.search);

        if (parsed.boxType) {
            // console.log("BT", parsed.diameter[1])
            this.state.boxType = parsed.boxType;
        }
        this.state.boxA = <BoxRectangle boxType={this.state.boxType} boxSide="A" key={this.state.boxType+ "A"} />;
        this.state.boxB = <BoxRectangle boxType={this.state.boxType} boxSide="B" key={this.state.boxType+ "B"} />
        this.state.boxC = <BoxRectangle boxType={this.state.boxType} boxSide="C" key={this.state.boxType+ "C"} />
        this.state.boxD = <BoxRectangle boxType={this.state.boxType} boxSide="D" key={this.state.boxType+ "D"} />
        this.state.boxE = <BoxRectangle boxType={this.state.boxType} boxSide="E" key={this.state.boxType+ "E"} />
        this.state.boxLid = <BoxRectangle boxType={this.state.boxType} boxSide="Lid" key={this.state.boxType+ "Lid"} />
        this.state.labelA = <BoxLabel boxType={this.state.boxType} boxSide="A" key={this.state.boxType+ "labelA"} />
        this.state.labelB = <BoxLabel boxType={this.state.boxType} boxSide="B" key={this.state.boxType+ "labelB"} />
        this.state.labelC = <BoxLabel boxType={this.state.boxType} boxSide="C" key={this.state.boxType+ "labelC"} />
        this.state.labelD = <BoxLabel boxType={this.state.boxType} boxSide="D" key={this.state.boxType+ "labelD"} />
        this.state.labelE = <BoxLabel boxType={this.state.boxType} boxSide="E" key={this.state.boxType+ "labelE"} />
        this.state.labelLid = <BoxLabel boxType={this.state.boxType} boxSide="Lid" key={this.state.boxType+ "labelLid"} />

        if (parsed.holes && parsed.boxSide && parsed.diameter && parsed.positionX && parsed.positionY) {
            if (Array.isArray(parsed.holes)) {
                this.state.holes = Object.keys(parsed.holes).map(key => {
                    // console.log(parsed.holes[key])
                    return <Hole key={key} boxType={this.state.boxType}  boxSide={parsed.boxSide[key]} holeId={`${parsed.holes[key]}`}
                    diameter={parsed.diameter[key]} positionX={parsed.positionX[key]} positionY={parsed.positionY[key]} />
                })
            } else {
                this.state.holes = [ <Hole key="hole1" boxType={this.state.boxType}  boxSide={parsed.boxSide} holeId={`${parsed.holes}`}
                    diameter={parsed.diameter} positionX={parsed.positionX} positionY={parsed.positionY} /> ]
            }
        } else {

            this.state.holes = Object.keys(this.holes).map(key =>
                <Hole key={key} boxType={this.state.boxType}  boxSide={this.holes[key].boxSide} holeId={`${this.holes[key].holeId}`}
                diameter={this.holes[key].diameter} positionX={this.holes[key].positionX} positionY={this.holes[key].positionY} />
            )
        }
    }

    // componentDidMount = () => {
    //     const parsed = queryString.parse(window.location.search);
    //     this.setState({
    //         boxType: parsed.boxType
    //     })

    //     this.handleBoxTypeChangeSelect();

    // }

    resetAllHoles = () => {
        this.setState({
            holes: ''
        })
    }

    resetHoles = (holeId) => {
        var array = [...this.state.holes];

        let newholes = array.filter((a) => a.key !== holeId)
        this.setState({
            holes: newholes
        })
    }

    addHole = (hole) => {

        var holes = {...this.state.holes };

        holes[`${hole.props.holeId}`] = hole

        const holeObjs = Object.keys(holes).map(key => {
            let holeColor = this.holesOverlap(holes[key]);
            return <Hole key={`${holes[key].props.holeId}`} boxType={this.state.boxType}  boxSide={holes[key].props.boxSide} holeId={`${holes[key].props.holeId}`}
                diameter={holes[key].props.diameter} positionX={holes[key].props.positionX} positionY={holes[key].props.positionY} holeColor={holeColor} />
        });
        // console.log('addHolesss', holeObjs)

        this.setState({ holes: holeObjs });

        return hole;
    }

    holesOverlap = (holea) => {
        const holes = {...this.state.holes };


        var holesOverlap;
        for (var key in Object.keys(holes)) {

            var holeb = holes[key]
            if ( holea.key !== holeb.key && holea.props.boxSide === holeb.props.boxSide) {
                let distSq = (holea.props.positionX - holeb.props.positionX) * (holea.props.positionX - holeb.props.positionX) + (holea.props.positionY - holeb.props.positionY) * (holea.props.positionY - holeb.props.positionY);
                let radSumSq = ((holea.props.diameter/2) + (holeb.props.diameter/2)) * ((holea.props.diameter/2) + (holeb.props.diameter/2));
                if (distSq == radSumSq) {
                    holesOverlap = "red";
                    break;
                } else if (distSq > radSumSq) {
                    holesOverlap = "blue";
                } else {
                    holesOverlap = "red";
                    break;
                }
            }

        };
        return holesOverlap;

    }

    handleBoxTypeChangeSelect = (val) => {
        // console.log('handleBoxTypeChangeSelect', val);
        if (val) {
            const holes = {...this.state.holes}

            let holeObjs = Object.keys(holes).map(key =>
                <Hole key={`${holes[key].props.holeId}`} boxType={val}  boxSide={holes[key].props.boxSide} holeId={`${holes[key].props.holeId}`}
                diameter={holes[key].props.diameter} positionX={holes[key].props.positionX} positionY={holes[key].props.positionY} />
            )

            this.setState({
                boxType: val,
                holes: holeObjs,
                boxA: <BoxRectangle boxType={val} boxSide="A" key={val+"A"} />,
                boxB: <BoxRectangle boxType={val} boxSide="B" key={val+"B"} />,
                boxC: <BoxRectangle boxType={val} boxSide="C" key={val+"C"} />,
                boxD: <BoxRectangle boxType={val} boxSide="D" key={val+"D"} />,
                boxE: <BoxRectangle boxType={val} boxSide="E" key={val+"E"} />,
                boxLid: <BoxRectangle boxType={val} boxSide="Lid" key={val+"Lid"} />,
                labelA: <BoxLabel boxType={val} boxSide="A" key={val+"labelA"} />,
                labelB: <BoxLabel boxType={val} boxSide="B" key={val+"labelB"} />,
                labelC: <BoxLabel boxType={val} boxSide="C" key={val+"labelC"} />,
                labelD: <BoxLabel boxType={val} boxSide="D" key={val+"labelD"} />,
                labelE: <BoxLabel boxType={val} boxSide="E" key={val+"labelE"} />,
                labelLid: <BoxLabel boxType={val} boxSide="Lid" key={val+"labelLid"} />
             })
             this.setState({holes: holeObjs})
        }
    }


    render() {

        return (
            <div>
                <Header />
                <Container fluid>
                    <Row id="box-wrapper">
                        <Col className="col pl-0" lg={4} md={5} sm={12} xs={12}>
                            <div id="form-wrapper">
                                <AddDesignFormV3 handleBoxTypeChangeSelect={this.handleBoxTypeChangeSelect} boxType={this.state.boxType} boxTypes={this.boxTypes} holes={this.state.holes} addHole={this.addHole} resetHoles={this.resetHoles} holesOverlap={this.holesOverlap} />
                            </div>
                        </Col>
                        <Col className="col" lg={8} md={7} sm={12} xs={12}>
                            <h3 className="text-left box-title">{this.state.boxType} Enclosure</h3>
                            <div id="svg-wrapper">
                            {/* 0 0 625 800 */}
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                    id="svg" key="svg" x="0px" y="0px" viewBox="0 0 720 930" enableBackground="new 0 0 720 930" >
                                    <defs>
                                        {this.generateRectangles()}

                                        {this.generateHoles()}

                                        {this.generateLabels()}

                                        <mask id="circlesMask">
                                            <use xlinkHref="#circles" overflow="visible"/>
                                        </mask>
                                    </defs>
                                    <use xlinkHref="#circles" overflow="visible" stroke="#000" strokeWidth="2"/>
                                    <use xlinkHref="#rectangles" overflow="visible" stroke="#000" strokeWidth="1"/>
                                    <use xlinkHref="#labels" overflow="visible" stroke="#eee" strokeWidth="0"/>

                                </svg>
                            </div>
                            <div className="card bg-light mb-3 mx-4" style={{'maxWidth':'55em'}}>
                                <div className="card-body">
                                    <p className="card-text">This form is only for illustration purpose and actual measurements for coordinates should be taken with actual enclosure itself.</p>
                                    <p className="card-text">If you wish to move hole to left side please put minus value in X value,
                                    for example if you put -4 it will move hole 4mm to left, if you put just "4" to X value then it will move hole to 4mm right side.
                                    Same applies to Y value, if you put minus value for example -4, it will move hole 4mm to downwards and if you just put "4" to Y value it will move hole 4mm upwards.
                                    If hole touch or exceed border it will turn to red color.</p>
                                    <p className="card-text">**Minimum hole size is 3mm. And please add 0.20mm to hole diameter for powder coating thickness, for example if you need 4mm hole size please put 4.2 value to hole size.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }

    generateRectangles = () => {
        // const boxType = this.props.boxType;
        return (
            <g id="rectangles">
                { this.state.boxA }
                { this.state.boxB }
                { this.state.boxC }
                { this.state.boxD }
                { this.state.boxE }
            </g>
        );
    }

    generateHoles = () => {
        return (
            <g id="circles">
                { this.renderHoles() }
            </g>
        );
    }


    renderHoles = () => {
        const holeObjs = this.state.holes;
        if (holeObjs.length > 0 ) {
            // console.log('holeObjs', holeObjs);
            return holeObjs;
        }
    }

    generateLabels = () => {
        return (
            <g id="labels">
                { this.state.labelA }
                { this.state.labelB }
                { this.state.labelC }
                { this.state.labelD }
                { this.state.labelE }
            </g>
        );
    }
}

export default BoxWrapperV3;