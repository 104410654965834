import React, { Component } from "react";
// import { createBrowserHistory } from 'history';

// import ReCAPTCHA from "react-google-recaptcha";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import OAuthService from './OAuthService';
import { humanize } from '../../box-helpers';

import {
    Spinner,
    Row, Col,
    Container,
    Form, FormGroup,
    Input,
    Button,
    Alert,
    Toast, ToastHeader, ToastBody
} from 'reactstrap';

class NewPassword extends Component {
    state = {
        passwordToken: "",
        password: "",
        password2: "",
        responseMsg: "",
        errorMsg: "",
        loading: false,
        mounted: false,
    }

    constructor(props){
        super(props);
        this.oas = new OAuthService();
    }

    componentDidMount = () => {
        this.oas.getToken.then((result) => {
            // console.log(result);
            if(result["access_token"]){
                localStorage.setItem('client_token', JSON.stringify(result["access_token"]));
            }else{
                localStorage.setItem('token', "");
            }

            this.setState({
                mounted: true
            })
        });

        let passwordToken = new URLSearchParams(this.props.location.search).get("reset_password_token");

        if (passwordToken) {
            this.setState({ passwordToken: passwordToken });
        }
    }

    putNewPassword = (e) => {
        e.preventDefault();

        if(this.state.password.trim() !== this.state.password2.trim()){
            // alert("Please make sure your password match.")
            this.setState({ alertMsg: "Please make sure your password match."})
            return;
        }

        this.setState({
            loading: true,
            responseMsg: "",
            alertMsg: "",
        }, async () => {
            var body_params = new URLSearchParams();
            body_params.append("box_customer[reset_password_token]", this.state.passwordToken.trim());
            body_params.append("box_customer[password]", this.state.password.trim());
            body_params.append("box_customer[password_confirmation]", this.state.password2.trim());

            let token = JSON.parse(localStorage.getItem("client_token"));
            var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/password', {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body_params.toString()
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error;
            });

            this.processResult(result);
        })
    }

    processResult = (result) => {
        this.setState({
            password: "",
            password2: "",
            loading: false
        }, () => {
            // console.log(result);
            if(result["errors"]){
                console.log('result error: ',result["errors"]);
                let errors = Object.keys(result["errors"]).map(key => {
                    let human_key = humanize(key);
                    return  human_key + " " + result["errors"][key];
                })
                // alert(errors);
                this.setState({ alertMsg: errors})
            }else{
                this.setState({responseMsg: "Password has been reset "});
            }
        })
    }

    setUserName = (e) => {
        this.setState({ userName: e.target.value });
    }

    setPassword = (e) => {
        this.setState({ password: e.target.value });
    }

    setPassword2 = (e) => {
        this.setState({ password2: e.target.value });
    }

    onCaptchaChange = (value) => {
        if(value === null){
            this.setState({
                captchaExpired: true
            });
        }else{
            this.setState({
                captchaExpired: false
            });
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row className="m-4">
                        <Col>
                            {!this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Reset Password
                                    </ToastHeader>
                                    <ToastBody>
                                        <Alert color="success" className="small-box mb-3 text-center" isOpen={this.state.responseMsg ? true : false } >
                                            <div className="text-medium">
                                                { this.state.responseMsg }
                                                <div>
                                                    <a className="" href="/login"><u>Login</u></a>
                                                </div>
                                            </div>
                                        </Alert>
                                        <Alert color="danger" className="small-box mb-3 text-center" isOpen={this.state.alertMsg && !this.state.responseMsg ? true : false } >
                                            <div className="text-medium">
                                                { this.state.alertMsg }
                                            </div>
                                        </Alert>
                                        <Form className="reset-password-form m-auto" onSubmit={this.putNewPassword} >
                                            <FormGroup>
                                                <Input
                                                    type="password"
                                                    value={this.state.password}
                                                    onChange={this.setPassword}
                                                    placeholder="Password"
                                                    minLength="5"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input
                                                    type="password"
                                                    value={this.state.password2}
                                                    onChange={this.setPassword2}
                                                    placeholder="Retype Password"
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Button type="submit" color="primary"
                                                    disabled={( this.state.password.trim().length == 0 || this.state.password2.trim().length == 0) && this.state.mounted ? true : false}
                                                    style={{width: '100%', marginTop: '10px', fontSize: '1em', padding: '2px'}}
                                                >
                                                    Reset Password
                                                </Button>

                                            </FormGroup>
                                        </Form>

                                    </ToastBody>
                                </Toast>
                            }
                            {this.state.loading &&
                                <Toast className="m-auto">
                                    <ToastHeader>
                                        Reseting...
                                    </ToastHeader>
                                    <ToastBody>
                                        <Spinner color="primary" className="m-auto d-flex"/>
                                    </ToastBody>
                                </Toast>
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default NewPassword;