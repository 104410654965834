import React from 'react';
import BoxRectangle from '../BoxDesign/BoxRectangle';
import BoxLabel from '../BoxDesign/BoxLabel';
import Hole from '../BoxDesign/Hole';
import Line from '../BoxDesign/Line';
import Shape from '../BoxDesign/Shape';
import CustomerDesignForm from './CustomerDesignForm';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

import {
    Row,
    Col,
    Container,
    // Input,
    // FormGroup,
    // Label,
    // Form,
    // Button
} from 'reactstrap';
import queryString from 'query-string';
import {getDefaultBoxType } from '../../box-helpers';

// 125B Viewport 515.97 x 723.87
// SIDE A CENTER 66.50 x 121.50mm  - 251.37 x 459.27
// SIDE B TOP 66.50 x 35mm - 251.37 x 132.3
// SIDE C LEFT 35 x 121.50mm  - 132.3 x 459.27
// SIDE D BOTTOM 66.50 x 35mm - 251.37 x 132.3
// SIDE E RIGHT 35 x 121.50mm - 132.3 x 459.27

class BoxDesignWrapper extends React.Component {
    holes = {
        // hole1: {
        //     positionX: 0,
        //     positionY: 0,
        //     diameter: 3,
        //     boxSide: getDefaultBoxSide(),
        //     holeId: "hole1"
        // },
        // hole1: {
        //     positionX: -15,
        //     positionY: 5,
        //     diameter: 2.55,
        //     boxSide: "A"
        // },
        // hole2: {
        //     positionX: 15,
        //     positionY: 5,
        //     diameter: 2.55,
        //     boxSide: "A"
        // }
    }
    state = {
        boxType: getDefaultBoxType(),
        holes: [],
        lines: [],
        shapes: [],
        boxA: {},
        boxB: {},
        boxC: {},
        boxD: {},
        boxE: {},
        boxLid: {},
        labelA: {},
        labelB: {},
        labelC: {},
        labelD: {},
        labelE: {},
        labelLid: {},
    }


    constructor(props) {
        super(props);

        this.handleBoxTypeChangeSelect = this.handleBoxTypeChangeSelect.bind(this)
        const parsed = queryString.parse(window.location.search);

        if (parsed.boxType) {
            // console.log("BT", parsed.diameter[1])
            this.state.boxType = parsed.boxType;
        }
        this.state.boxA = <BoxRectangle boxType={this.state.boxType} boxSide="A" key={this.state.boxType+ "A"} />;
        this.state.boxB = <BoxRectangle boxType={this.state.boxType} boxSide="B" key={this.state.boxType+ "B"} />
        this.state.boxC = <BoxRectangle boxType={this.state.boxType} boxSide="C" key={this.state.boxType+ "C"} />
        this.state.boxD = <BoxRectangle boxType={this.state.boxType} boxSide="D" key={this.state.boxType+ "D"} />
        this.state.boxE = <BoxRectangle boxType={this.state.boxType} boxSide="E" key={this.state.boxType+ "E"} />
        this.state.boxLid = <BoxRectangle boxType={this.state.boxType} boxSide="Lid" key={this.state.boxType+ "Lid"} />
        this.state.labelA = <BoxLabel boxType={this.state.boxType} boxSide="A" key={this.state.boxType+ "labelA"} />
        this.state.labelB = <BoxLabel boxType={this.state.boxType} boxSide="B" key={this.state.boxType+ "labelB"} />
        this.state.labelC = <BoxLabel boxType={this.state.boxType} boxSide="C" key={this.state.boxType+ "labelC"} />
        this.state.labelD = <BoxLabel boxType={this.state.boxType} boxSide="D" key={this.state.boxType+ "labelD"} />
        this.state.labelE = <BoxLabel boxType={this.state.boxType} boxSide="E" key={this.state.boxType+ "labelE"} />
        this.state.labelLid = <BoxLabel boxType={this.state.boxType} boxSide="Lid" key={this.state.boxType+ "labelLid"} />

        if (parsed.holes && parsed.boxSide && parsed.diameter && parsed.positionX && parsed.positionY) {
            if (Array.isArray(parsed.holes)) {
                this.state.holes = Object.keys(parsed.holes).map(key => {
                    // console.log(parsed.holes[key])
                    return <Hole key={key} boxType={this.state.boxType}  boxSide={parsed.boxSide[key]} holeId={`${parsed.holes[key]}`}
                    diameter={parsed.diameter[key]} positionX={parsed.positionX[key]} positionY={parsed.positionY[key]} />
                })
            // } else {
            //     this.state.holes = [ <Hole key="hole1" boxType={this.state.boxType}  boxSide={parsed.boxSide} holeId={`${parsed.holes}`}
            //         diameter={parsed.diameter} positionX={parsed.positionX} positionY={parsed.positionY} /> ]
            }
        } else {

            // this.state.holes = Object.keys(this.holes).map(key =>
            //     <Hole key={key} boxType={this.state.boxType}  boxSide={this.holes[key].boxSide} holeId={`${this.holes[key].holeId}`}
            //     diameter={this.holes[key].diameter} positionX={this.holes[key].positionX} positionY={this.holes[key].positionY} />
            // )
        }

        if (parsed.lines && parsed.boxSide && parsed.positionX1 && parsed.positionY1 && parsed.positionX2 && parsed.positionY2) {
            if (Array.isArray(parsed.lines)) {
                this.state.lines = Object.keys(parsed.lines).map(key => {
                    return <Line key={key} boxType={this.state.boxType}  boxSide={parsed.props.boxSide} lineId={`${parsed.lines[key]}`} positionX1={parsed.positionX1[key]} positionY1={parsed.positionY1[key]} positionX2={parsed.positionX2[key]} positionY2={parsed.positionY2[key]} />
                })
            }
        }

        if (parsed.shapes && parsed.boxSide && parsed.shapeType && parsed.positionX && parsed.positionY && parsed.width && parsed.height) {
            if (Array.isArray(parsed.shapes)) {
                this.state.shapes = Object.keys(parsed.shapes).map(key => {
                    return <Shape key={key} boxType={this.state.boxType}  boxSide={parsed.props.boxSide} shapeType={`${parsed.shapeType[key]}`} shapeId={`${parsed.shapes[key]}`} positionX={parsed.positionX[key]} positionY={parsed.positionY[key]} width={parsed.width[key]} height={parsed.height[key]} />
                })
            }
        }
    }

    // componentDidMount = () => {
    //     const parsed = queryString.parse(window.location.search);
    //     this.setState({
    //         boxType: parsed.boxType
    //     })

    //     this.handleBoxTypeChangeSelect();

    // }

    componentDidMount() {
        let el = document.getElementById("box-design-wrapper")
        let rect = el.getBoundingClientRect();
        el.style.height = rect.height + "px";
    }

    resetAllHoles = () => {
        this.setState({
            holes: ''
        })
    }

    resetHoles = (holeId) => {
        var array = [...this.state.holes];

        let newholes = array.filter((a) => a.key !== holeId)
        // console.log(holeId);
        // console.log(newholes);
        this.setState({
            holes: newholes
        })
    }

    addHole = (hole) => {

        var holes = {...this.state.holes };
        holes[`${hole.props.holeId}`] = hole

        const holeObjs = Object.keys(holes).map(key => {
            let holeColor = this.holesOverlap(holes[key]);
            return <Hole key={`${holes[key].props.holeId}`} boxType={this.state.boxType}  boxSide={holes[key].props.boxSide} holeId={`${holes[key].props.holeId}`}
                diameter={holes[key].props.diameter} positionX={holes[key].props.positionX} positionY={holes[key].props.positionY} holeColor={holeColor} />
        });
        // console.log('addHolesss', holeObjs)

        this.setState({ holes: holeObjs }, () => {
            // console.log(this.state.holes);
        });
        return hole;
    }

    holesOverlap = (holea) => {
        const holes = {...this.state.holes };


        var holesOverlap;
        for (var key in Object.keys(holes)) {

            var holeb = holes[key]
            if ( holea.key !== holeb.key && holea.props.boxSide === holeb.props.boxSide) {
                let distSq = (holea.props.positionX - holeb.props.positionX) * (holea.props.positionX - holeb.props.positionX) + (holea.props.positionY - holeb.props.positionY) * (holea.props.positionY - holeb.props.positionY);
                let radSumSq = ((holea.props.diameter/2) + (holeb.props.diameter/2)) * ((holea.props.diameter/2) + (holeb.props.diameter/2));
                if (distSq == radSumSq) {
                    holesOverlap = "red";
                    break;
                } else if (distSq > radSumSq) {
                    holesOverlap = "blue";
                } else {
                    holesOverlap = "red";
                    break;
                }
            }

        };
        return holesOverlap;

    }

    resetAllLines = () => {
        this.setState({
            lines: ''
        })
    }

    resetLines = (lineId) => {
        var array = [...this.state.lines];

        let newlines = array.filter((a) => a.key !== lineId)
        // console.log(lineId);
        // console.log(newlines);
        this.setState({
            lines: newlines
        })
    }

    addLine = (line) => {

        var lines = {...this.state.lines };
        lines[`${line.props.lineId}`] = line
        const lineObjs = Object.keys(lines).map(key => {
            let lineColor = ""; //this.linesOverlap(lines[key]);
            return <Line key={`${lines[key].props.lineId}`} boxType={this.state.boxType}  boxSide={lines[key].props.boxSide} lineId={`${lines[key].props.lineId}`}
                positionX1={lines[key].props.positionX1} positionY1={lines[key].props.positionY1} positionX2={lines[key].props.positionX2} positionY2={lines[key].props.positionY2} lineColor={lineColor} />
        });

        this.setState({ lines: lineObjs }, () => {
            // console.log(this.state.lines);
        });
        return line;
    }
    linesOverlap = (linea) => {
        const lines = {...this.state.lines };


        var linesOverlap;
        for (var key in Object.keys(lines)) {

            var holeb = lines[key]
            if ( linea.key !== holeb.key && linea.props.boxSide === holeb.props.boxSide) {
                let distSq = (linea.props.positionX - holeb.props.positionX) * (linea.props.positionX - holeb.props.positionX) + (linea.props.positionY - holeb.props.positionY) * (linea.props.positionY - holeb.props.positionY);
                let radSumSq = ((linea.props.diameter/2) + (holeb.props.diameter/2)) * ((linea.props.diameter/2) + (holeb.props.diameter/2));
                if (distSq == radSumSq) {
                    linesOverlap = "red";
                    break;
                } else if (distSq > radSumSq) {
                    linesOverlap = "blue";
                } else {
                    linesOverlap = "red";
                    break;
                }
            }

        };
        return linesOverlap;

    }

    resetAllShapes = () => {
        this.setState({
            shapes: ''
        })
    }

    resetShapes = (shapeId) => {
        var array = [...this.state.shapes];

        let newshapes = array.filter((a) => a.key !== shapeId)
        // console.log(lineId);
        // console.log(newlines);
        this.setState({
            shapes: newshapes
        })
    }

    addShape = (shape) => {

        var shapes = {...this.state.shapes };
        shapes[`${shape.props.shapeId}`] = shape
        const shapeObjs = Object.keys(shapes).map(key => {
            let shapeColor = ""; //this.linesOverlap(lines[key]);
            return <Shape key={`${shapes[key].props.shapeId}`} boxType={this.state.boxType}  boxSide={`${shapes[key].props.boxSide}`} shapeType={`${shapes[key].props.shapeType}`} shapeId={`${shapes[key].props.shapeId}`}
                positionX={`${shapes[key].props.positionX}`} positionY={`${shapes[key].props.positionY}`} width={`${shapes[key].props.width}`} height={`${shapes[key].props.height}`} shapeColor={shapeColor} />
        });

        this.setState({ shapes: shapeObjs }, () => {
            // console.log(this.state.shapes);
        });
        return shape;
    }

    handleBoxTypeChangeSelect = (val) => {
        // console.log('handleBoxTypeChangeSelect', val);
        if (val) {
            const holes = {...this.state.holes}

            let holeObjs = Object.keys(holes).map(key =>
                <Hole key={`${holes[key].props.holeId}`} boxType={val}  boxSide={holes[key].props.boxSide} holeId={`${holes[key].props.holeId}`}
                diameter={holes[key].props.diameter} positionX={holes[key].props.positionX} positionY={holes[key].props.positionY} />
            )
            const lines = {...this.state.lines}

            let lineObjs = Object.keys(lines).map(key =>
                <Line key={`${lines[key].props.lineId}`} boxType={val}  boxSide={lines[key].props.boxSide} lineId={`${lines[key].props.lineId}`}
                positionX1={lines[key].props.positionX1} positionY1={lines[key].props.positionY1} positionX2={lines[key].props.positionX2} positionY2={lines[key].props.positionY2} />
            )

            const shapes = {...this.state.shapes}
            let shapeObjs = Object.keys(shapes).map(key =>
                <Shape key={`${shapes[key].props.shapeId}`} boxType={val} boxSide={shapes[key].props.boxSide} shapeType={shapes[key].props.shapeType} shapeId={`${shapes[key].props.shapeId}`}
                positionX={shapes[key].props.positionX} positionY={shapes[key].props.positionY} width={shapes[key].props.width} height={shapes[key].props.height}  />
                )

            this.setState({
                boxType: val,
                holes: holeObjs,
                lines: lineObjs,
                shapes: shapeObjs,
                boxA: <BoxRectangle boxType={val} boxSide="A" key={val+"A"} />,
                boxB: <BoxRectangle boxType={val} boxSide="B" key={val+"B"} />,
                boxC: <BoxRectangle boxType={val} boxSide="C" key={val+"C"} />,
                boxD: <BoxRectangle boxType={val} boxSide="D" key={val+"D"} />,
                boxE: <BoxRectangle boxType={val} boxSide="E" key={val+"E"} />,
                boxLid: <BoxRectangle boxType={val} boxSide="Lid" key={val+"Lid"} />,
                labelA: <BoxLabel boxType={val} boxSide="A" key={val+"labelA"} />,
                labelB: <BoxLabel boxType={val} boxSide="B" key={val+"labelB"} />,
                labelC: <BoxLabel boxType={val} boxSide="C" key={val+"labelC"} />,
                labelD: <BoxLabel boxType={val} boxSide="D" key={val+"labelD"} />,
                labelE: <BoxLabel boxType={val} boxSide="E" key={val+"labelE"} />,
                labelLid: <BoxLabel boxType={val} boxSide="Lid" key={val+"labelLid"} />
             })
            //  this.setState({holes: holeObjs})
        }
    }


    render() {

        return (
            <div>
                <Header />
                <Container fluid>
                    <Row id="box-wrapper">
                        <Col className="col pl-0" lg={4} md={5} sm={12} xs={12}>
                            <div id="form-wrapper">
                                <CustomerDesignForm handleBoxTypeChangeSelect={this.handleBoxTypeChangeSelect} boxType={this.state.boxType} boxTypes={this.boxTypes}
                                holes={this.state.holes} addHole={this.addHole} resetHoles={this.resetHoles} resetAllHoles={this.resetAllHoles} holesOverlap={this.holesOverlap}
                                lines={this.state.lines} addLine={this.addLine} resetLines={this.resetLines} resetAllLines={this.resetAllLines} linesOverlap={this.linesOverlap}
                                shapes={this.state.shapes} addShape={this.addShape} resetShapes={this.resetShapes} resetAllShapes={this.resetAllShapes} shapesOverlap={this.shapesOverlap}
                                />
                            </div>
                        </Col>
                        <Col className="col sticky-top" lg={8} md={7} sm={12} xs={12} id="box-design-wrapper">
                            <h3 className="text-left box-title">{this.state.boxType} Enclosure</h3>
                            <div id="svg-wrapper" className='wrapper-with-lid'>
                            {/* 0 0 625 800 */}
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                    id="svg" key="svg" x="0px" y="0px" viewBox="0 0 1310 930" enableBackground="new 0 0 1310 930" >
                                    <defs>
                                        {this.generateRectangles()}

                                        {this.generateHoles()}

                                        {this.generateLines()}

                                        {this.generateShapes()}

                                        {this.generateLabels()}

                                        <mask id="circlesMask">
                                            <use xlinkHref="#circles" overflow="visible"/>
                                        </mask>
                                        <mask id="linesMask">
                                            <use xlinkHref="#lines" overflow="visible"/>
                                        </mask>
                                        <mask id="shapesMask">
                                            <use xlinkHref="#shapes" overflow="visible"/>
                                        </mask>
                                    </defs>
                                    <use xlinkHref="#circles" overflow="visible" stroke="#000" strokeWidth="2"/>
                                    <use xlinkHref="#lines" overflow="visible" stroke="#000" strokeWidth="2"/>
                                    <use xlinkHref="#shapes" overflow="visible" stroke="#000" strokeWidth="2"/>
                                    <use xlinkHref="#rectangles" overflow="visible" stroke="#000" strokeWidth="1"/>
                                    <use xlinkHref="#labels" overflow="visible" stroke="#eee" strokeWidth="0"/>

                                </svg>
                            </div>
                            <div className="card bg-light mb-3 mx-4" style={{'maxWidth':'55em'}}>
                                <div className="card-body">
                                    <p className="card-text text-danger">This form is only for illustration purposes and actual measurements for coordinates should be taken with actual enclosure itself. The mounting holes for the bottom plate are usually in the bottom corners of side plates.</p>
                                    <p className="card-text">If you wish to move hole to left side please put minus value in X value,
                                    for example if you put -4 it will move hole 4mm to left, if you put just "4" to X value then it will move hole to 4mm right side.
                                    Same applies to Y value, if you put minus value for example -4, it will move hole 4mm to downwards and if you just put "4" to Y value it will move hole 4mm upwards.
                                    If hole touch or exceed border it will turn to red color.</p>
                                    <p className="card-text">**Minimum hole size is 3mm. And please add 0.20mm to hole diameter for powder coating thickness, for example if you need 4mm hole size please put 4.2 value to hole size.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }

    generateRectangles = () => {
        // const boxType = this.props.boxType;
        return (
            <g id="rectangles">
                { this.state.boxA }
                { this.state.boxB }
                { this.state.boxC }
                { this.state.boxD }
                { this.state.boxE }
                { this.state.boxLid }
            </g>
        );
    }

    generateHoles = () => {
        return (
            <g id="circles">
                { this.renderHoles() }
            </g>
        );
    }


    renderHoles = () => {
        const holeObjs = this.state.holes;
        if (holeObjs.length > 0 ) {
            // console.log('holeObjs', holeObjs);
            return holeObjs;
        }
    }

    generateLines = () => {
        return (
            <g id="lines">
                { this.renderLines() }
            </g>
        );
    }


    renderLines = () => {
        const lineObjs = this.state.lines;
        if (lineObjs.length > 0 ) {
            // console.log('lineObjs', lineObjs);
            return lineObjs;
        }
    }

    generateShapes = () => {
        return (
            <g id="shapes">
                { this.renderShapes() }
            </g>
        );
    }


    renderShapes = () => {
        const shapeObjs = this.state.shapes;
        if (shapeObjs.length > 0 ) {
            // console.log('shapeObjs', shapeObjs);
            return shapeObjs;
        }
    }

    generateLabels = () => {
        return (
            <g id="labels">
                { this.state.labelA }
                { this.state.labelB }
                { this.state.labelC }
                { this.state.labelD }
                { this.state.labelE }
                { this.state.labelLid }
            </g>
        );
    }
}

export default BoxDesignWrapper;