import React, { Component } from "react";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Pagination from "../Layout/Pagination";

import OAuthService from '../Auth/OAuthService';

import {
    Row, Col,
    Container,
    Button,
    // Table
    Alert
} from 'reactstrap';
import BoxOrder from "./BoxOrder";
import BoxJob from "./BoxJob";

import { humanize } from '../../box-helpers'

class Archive extends Component {
    state = {
        user: false,
        box_orders: [],
        box_jobs: [],
        box_order_rows: [],
        confirmation_message: "",
        errors: false
    }

    constructor(props){
        super(props);
        this.os = new OAuthService();
    }

    componentDidMount = () => {

    }

    // resendConfirmation =() => {
    //     let token = JSON.parse(localStorage.getItem("token"));
    //     this.os.resendConfirmation(token).then((result) => {
    //         this.setState({confirmation_message: result['message']})
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <div className="mb-2 text-center">
                        <h3>Archive Box Templates</h3>
                    </div>
                    <Row className="m-4">
                        <Col className="col" md={6}>
                            <div>
                                <h5 className="float-left">My Drill Templates</h5>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_boxDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_designs'}
                                    data_attr={"box_designs"}
                                    redirect_to={"box-designs"}
                                    show_archived={true}
                                />
                            </div>
                        </Col>
                        <Col className="col" md={6}>
                            <div>
                                <h5 className="float-left">My UV Print Templates</h5>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_boxUVDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs'}
                                    data_attr={"box_uv_designs"}
                                    redirect_to={"box-uv-designs"}
                                    show_archived={true}
                                />
                            </div>
                        </Col>

                        <Alert style={{marginTop: '20px', width: '100%'}} color="dark" isOpen={ true } className="text-center">
                            <h5>
                                Order high quality enclosures, custom drill and UV services at <a href="https://www.taydaelectronics.com/hardware/enclosures.html" target="_blank" rel="noopener noreferrer">Tayda Electronics.com</a>
                            </h5>
                        </Alert>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default Archive;
