import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import {
    // Row,
    Col,
    Container,
    Input,
    FormGroup,
    Label,
    //FormText,
    // Form,
    Alert,
    Button,
    Collapse
} from 'reactstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getDefaultBoxType, getBoxTypes, getBoxSides, getApiUrl } from '../../box-helpers';

class CustomerUvDesignForm extends Component {

    state ={
        boxUVDesign: null,
        boxType: getDefaultBoxType(),
        boxTypes: getBoxTypes(),
        boxSides: getBoxSides(),
        designName: '',
        isArchived: false,
        fileError: false,
        success: '',
        collapse: false,
        glossLayer: "",
        colorLayer: "",
        whiteLayer: "",
        rdgWhiteLayer: "",
        rdgGlossLayer: "",
        glossType: "",
        glossCollapse: false,
        colorCollapse: false,
        whiteCollapse: false,
        collapseText: "Collapse",
        errors: {
            designName: '',
            boxType: '',
            boxSide: '',
            uploadFile: '',
            form: ''
        },
        loading: false
    }

    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        // console.log('holes', this.props.holes)

        this.state.boxType = props.boxType;

    }

    componentDidMount = () => {
        const parsed = queryString.parse(window.location.search);
        if(parsed["id"]){
            this.setState({
                loading: true
            }, () => {
                this.getBoxUVDesign(parsed.id)
            })
        }
    }

    getBoxUVDesign = async (id) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
        console.log(result);
        if(result["box_uv_design"]){
            this.setState({
                boxUVDesign: result.box_uv_design
            }, () => {
                this.setBoxUVDesign()
            })
        }else{
            this.setState({
                loading: false
            }, () => {
                if(result["message"]) alert(result.message);
                const history = createBrowserHistory();
                history.push({ pathname: "/box-uv-designs/new" })
                window.location.reload();
            })
        }
    }

    setBoxUVDesign = () => {
        let box_uv_design = JSON.parse(JSON.stringify(this.state.boxUVDesign));

        if (box_uv_design.active_box_job === true) {
            alert("There is currently a Box Job In progress and this design cannot be edited.");
            const history = createBrowserHistory();
            history.push({ pathname: "/dashboard" })
            window.location.reload();
        }

        this.setState({
            designName: box_uv_design.name,
            boxType: box_uv_design.enclosure_type,
            boxSide: box_uv_design.box_side,
            glossLayer: {value: box_uv_design.gloss_layer, label: box_uv_design.gloss_layer},
            colorLayer: {value: box_uv_design.color_layer, label: box_uv_design.color_layer},
            whiteLayer: {value: box_uv_design.white_layer, label: box_uv_design.white_layer},
            rdgWhiteLayer: box_uv_design.white_layer == "Yes" ? box_uv_design.rdg_white_swatch_color ? { value: box_uv_design.rdg_white_swatch_color, label: box_uv_design.rdg_white_swatch_color == "No" ? "No (white layer will not print)" : box_uv_design.rdg_white_swatch_color} : "" : "",
            rdgGlossLayer: box_uv_design.gloss_layer == "Yes" ? box_uv_design.rdg_gloss_swatch_color ? { value: box_uv_design.rdg_gloss_swatch_color, label: box_uv_design.rdg_gloss_swatch_color == "No" ? "No (gloss layer will not print)" : box_uv_design.rdg_gloss_swatch_color} : "" : "",
            glossType: box_uv_design.gloss_layer == "Yes" ? box_uv_design.gloss_print_type ? { value: box_uv_design.gloss_print_type, label: box_uv_design.gloss_print_type } : "" : "",
            glossCollapse: box_uv_design.gloss_layer == "Yes" ? true : false,
            colorCollapse: box_uv_design.color_layer == "Yes" ? true : false,
            whiteCollapse: box_uv_design.white_layer == "Yes" ? true : false,
            isArchived: box_uv_design.is_archived == 0 ? false : true,
            fileError: box_uv_design.file_error ? true : false,
            loading: false
        });
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    handleSubmit = async (e) => {
        e.preventDefault();
        let target = e.target;
        let msg = this.validateFormValues(e.target);
        let errors = this.state.errors

        let isValid = this.validateForm(this.state.errors)

        if(isValid && msg === '') {
            errors.form = false
            this.setState({errors})

            // console.log("FILES", e.target.uploadFile.files);
            // console.log("FILESENCODED",btoa(e.target.uploadFile.files[0]))
            //this.setState({uploadingFile: true})
            this.setState({ loading: true})

            let bodyParams = null;
            if(target.uploadFile.files[0]){
                let encodedFile = await this.toBase64(target.uploadFile.files[0]);
                bodyParams = JSON.stringify({
                    name: target.designName.value.trim(),
                    enclosure_type: target.boxType.value.trim(),
                    box_side: target.boxSide.value.trim(),
                    color_layer: target.colorLayer.value.trim(),
                    white_layer: target.whiteLayer.value.trim(),
                    rdg_white_layer: target.rdgWhiteLayer.value.trim(),
                    gloss_layer: target.glossLayer.value.trim(),
                    rdg_gloss_layer: target.rdgGlossLayer.value.trim(),
                    gloss_type: target.glossType.value.trim(),
                    upload_file: encodedFile,
                    is_archived: this.state.boxUVDesign ? target.isArchived.checked ? 1 : 0 : 0,
                })
            }else{
                bodyParams = JSON.stringify({
                    name: target.designName.value.trim(),
                    enclosure_type: target.boxType.value.trim(),
                    box_side: target.boxSide.value.trim(),
                    color_layer: target.colorLayer.value.trim(),
                    white_layer: target.whiteLayer.value.trim(),
                    rdg_white_layer: target.rdgWhiteLayer.value.trim(),
                    gloss_layer: target.glossLayer.value.trim(),
                    rdg_gloss_layer: target.rdgGlossLayer.value.trim(),
                    gloss_type: target.glossType.value.trim(),
                    is_archived: this.state.boxUVDesign ? target.isArchived.checked ? 1 : 0 : 0,
                })
            }

            this.setState({
                loading: true
            }, async () => {
                let token = JSON.parse(localStorage.getItem("token"));
                var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs' + (this.state.boxUVDesign != null ? '/' + this.state.boxUVDesign.id : ''), {
                    method: this.state.boxUVDesign != null ? 'PUT' : 'POST',
                    headers: {
                        Authorization: "Bearer " + token,
                        'Content-Type': 'application/json'
                    },
                    body: bodyParams
                })
                .then(response => response.json())
                .then(data => {
                    return data;
                })
                .catch((error) => {
                    return error;
                });
                // console.log(result);
                this.setState({
                    loading: false
                }, () => {
                    if(result["box_uv_design"]){
                        toast.success('UV print design successfully saved.');
                        if(this.state.boxUVDesign == null){
                            const history = createBrowserHistory();
                            history.push({ pathname: "/box-uv-designs/edit?id=" + result["box_uv_design"].id })
                        }
                        this.setState({
                            boxUVDesign: result["box_uv_design"]
                        })
                    } else {
                        if(result["message"]) alert(result.message);
                    }
                })

                // this.processResult(result);
            })

            // fetch(getApiUrl(), requestOptions)
                //     .then(this.handleErrors)
                //     .then(data => {
                //         if (this.state.errors.form !== "" && !data.message.includes('Success')) {
                //             errors.form = data.message;
                //             this.setState({  errors, uploadingFile: false  })
                //         } else {
                //             window.scrollTo(0, 0)
                //             this.setState({ success: data.message, uploadingFile: false })
                //         }
                //         return data;
                //     }).catch(function(error) {
                //         console.log(error);
                //         this.setState({ uploadingFile: false  })
                //     });
        }
    }

    handleErrors = (response) => {
        let errors = this.state.errors
        if (!response.ok) {
            errors.form = "ERROR"
            this.setState({errors})
            //this.setState({errors, uploadingFile: false})
            // throw Error(response.statusText);
        }
        return response.json();
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateFormValues = (formObj) => {
        let msg = '';

        if (formObj.designName.value.trim() === '') {
            msg = 'Please add a name to your design';
        } else if (this.state.whiteCollapse === true && formObj.rdgWhiteLayer.value.trim() === '') {
            msg = 'Print Pass White Layer answer required!';
        } else if (formObj.glossLayer.value.trim() === '') {
            msg = 'Gloss Layer answer required!';
        } else if (formObj.uploadFile.value === '' && this.state.boxUVDesign == null) {
            msg = 'Design File required!';
        } else if (formObj.agreementTerms.checked != true) {
            msg = 'Please agree to terms by checking the checkbox';
        } else if (formObj.doubleCheckTerms.checked != true) {
            msg = 'Please agree to terms by  double checking your design and check the checkbox';
        } else if (formObj.sizeAgreementTerms.checked != true) {
            msg = 'Please verify that you have reviewed the sizes by checking the checkbox';
        }

        let errors = this.state.errors
        errors.form = msg
        this.setState({errors})

        return msg;
    }

    validateFormFields = (name, value) => {
        let errors = this.state.errors;
        switch (name) {

            case 'designName':
                errors.designName = '';
                break;

            default:
                break;
        }
        // if (name != 'color') {
        //     errors.color = '';
        // }
        return errors;
    }

    handleOrderChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            if (value.length >8) {
            // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
            fetch(getApiUrl() + "/search?order_number="+value, requestOptions)
                .then(this.handleErrors)
                .then(data => {
                    if (this.state.errors.form !== "" && !data.message.includes('Success')) {
                        errors.form = data.message;
                        this.setState({  errors })
                    } else {
                        // console.log('data', data);
                        this.setState({ sku: "", enclosureSku: "", skulabel: "", enclosureSkulabel: "", boxJoblabel: "", glossSku: "", glossSkulabel: "",
                                        skus: [], enclosures: [], availableQuantities: [], availableJobs: [], allJobs: [] })
                        let quantity = 0
                        if (data && data.uv_products && data.uv_products.length > 0) {
                            let uv_products = data.uv_products.reduce((results, key) => {
                                if (key.available_quantity > 0) {
                                    quantity = key.available_quantity;
                                    results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        available_quantity: key.available_quantity,
                                        box_type: key.attribute_value
                                    });
                                }
                                return results;
                            }, [])
                            // console.log('uv_products', uv_products);
                            let availableQuantities = []
                            for (let i = 1; i <= quantity; i++) {
                                availableQuantities.push(i);
                            }

                            let allJobs = []
                            if (data && data.drill_box_jobs && data.drill_box_jobs.length > 0) {
                                allJobs = data.drill_box_jobs.map((key) => {
                                    return {
                                        value: key.id,
                                        label: key.qty + " " + key.name + " " + key.enclosure_type + " " + key.powder_color,
                                        qty:  key.qty,
                                        name:  key.name,
                                        enclosure_type:  key.enclosure_type,
                                        powder_color: key.powder_color
                                    }
                                })
                            }
                            if (allJobs.length === 0) {
                                allJobs = [{value: 0, label: "No Existing Jobs Found. (Optional)"}]
                            }

                            this.setState({
                                skus: uv_products,
                                availableQuantities: availableQuantities,
                                allJobs: allJobs
                            })

                        }

                        if (data && data.enclosure_products && data.enclosure_products.length > 0) {
                            let enclosure_products = data.enclosure_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_uv_quantity > 0) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_uv_quantity + " - " +key.name + " " + key.sku,
                                        box_type: key.attribute_value,
                                        available_quantity: key.available_uv_quantity,
                                        color: key.enclosure_color,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('enclosure_products', enclosure_products);
                            this.setState({ allEnclosures: enclosure_products })
                        }

                        let color_products = []
                        if (data && data.color_products && data.color_products.length > 0) {
                            color_products = data.color_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_quantity > 0 && key.available_quantity >= quantity) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        // box_type: key.attribute_value,
                                        available_quantity: key.available_quantity,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('gloss_products', gloss_products);
                        }

                        // if (color_products.length === 0) {
                        //     color_products = [{value: 0, label:  "NO ADDITIONAL SERVICE NEEDED FOR THIS LAYER."}]
                        // } else {
                            color_products.push({ value: 0, label: "NO ADDITIONAL SERVICE NEEDED FOR THIS JOB."})
                        // }

                        let white_products = []
                        if (data && data.white_products && data.white_products.length > 0) {
                            white_products = data.white_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_quantity > 0 && key.available_quantity >= quantity) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        // box_type: key.attribute_value,
                                        available_quantity: key.available_quantity,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('gloss_products', gloss_products);
                        }

                        // if (white_products.length === 0) {
                        //     white_products = [{value: 0, label:  "There was no white service or not enough white service bought with this order."}]
                        // } else {
                            white_products.push({ value: 0, label: "NO ADDITIONAL SERVICE NEEDED FOR THIS JOB."})
                        // }

                        let gloss_products = []
                        if (data && data.gloss_products && data.gloss_products.length > 0) {
                            gloss_products = data.gloss_products.reduce((results, key) => {
                                // console.log('key1', key)
                                if (key.available_quantity > 0 && key.available_quantity >= quantity) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        // box_type: key.attribute_value,
                                        available_quantity: key.available_quantity,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])

                            // console.log('gloss_products', gloss_products);
                        }

                        // if (gloss_products.length === 0) {
                        //     gloss_products = [{value: 0, label:  "There was no gloss service or not enough gloss service bought with this order."}]
                        // } else {
                            gloss_products.push({ value: 0, label: "NO ADDITIONAL SERVICE BOUGHT FOR THIS JOB. (GLOSS LAYER WILL NOT PRINT)"})
                        // }

                        this.setState({ glossProducts: gloss_products, colorProducts: color_products, whiteProducts: white_products })
                    }
                }).catch(function(error) {
                    console.log(error);
                });
            }
            this.setState({errors, [name]: value})
        }
    }

    handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);
            this.setState({errors, [name]: value})
        }
    }

    handleSkuSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            let currentEnclosures = this.state.allEnclosures
            let availableEnclosures = []
            if (currentEnclosures.length > 0) {
                let isCombo = false;
                this.setState({ enclosureSku: "", enclosureSkulabel: "" })
                availableEnclosures  = currentEnclosures.reduce((result, key) => {
                    // console.log('key', key)
                    if (key && key.product_id === e.product_id) {
                        result = [{ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id }]
                        isCombo = true
                    } else if (isCombo === false && key && key.box_type === e.box_type) {
                        result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id  })
                    }
                    return result;
                }, [])
            }
            if (availableEnclosures.length === 0) {
                availableEnclosures = [{value: 0, label: "No Enclosures available"}]
            }

            this.setState({[name]: value, [name+"label"]: label, enclosures: availableEnclosures})
        }
    }

    handleSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            this.setState({[name]: value, [name+"label"]: label})
        }
    }

    handleTypeChange = (e) => {
        if (e) {
            // console.log(e);
            const {name, value, label} = e;


            this.setState({boxType: e.value, color: e.color, [name]: value , [name+"label"]: label });
            this.props.handleBoxTypeChangeSelect(e.value);
        }
    }

    handleSideChange = (e) => {
        if (e) {
            // console.log(e);
            this.setState({boxSide: e.value});
        }
    }

    handleColorChange = (e) => {
        if (e) {
            this.setState({
                colorLayer: {value: e.value, label: e.value}
            }, () => {
                if (e.value == "Yes") {
                    this.setState({colorCollapse: true})
                } else if (e.value == "No") {
                    this.setState({colorCollapse: false})
                }
            })
        }
    }

    handleWhiteChange = (e) => {
        if (e) {
            this.setState({
                whiteLayer: {value: e.value, label: e.value}
            }, () => {
                if (e.value == "Yes") {
                    this.setState({whiteCollapse: true})
                } else if (e.value == "No") {
                    this.setState({whiteCollapse: false})
                }
            })
        }
    }

    handleRDGWhiteChange = (e) => {
        if (e) {
            if (e.value == "Yes") {
                this.setState({rdgWhiteLayer: { value: "Yes", label: "Yes" }})
            } else if (e.value == "No") {
                this.setState({rdgWhiteLayer: { value: "No" , label: "No (white layer will not print)"}})
            }
        }
    }

    handleGlossChange = (e) => {
        if (e) {
            this.setState({
                glossLayer: {value: e.value, label: e.value}
            }, () => {
                if (e.value == "Yes") {
                    this.setState({glossCollapse: true})
                } else if (e.value == "No") {
                    this.setState({glossCollapse: false})
                }
            })
        }
    }

    handleRDGGlossChange = (e) => {
        if (e) {
            if (e.value == "Yes") {
                this.setState({rdgGlossLayer: { value: "Yes", label: "Yes" }})
            } else if (e.value == "No") {
                this.setState({rdgGlossLayer: { value: "No" , label: "No (gloss layer will not print)"}})
            }
        }
    }

    handleGlossTypeChange = (e) => {
        if (e) {
            this.setState({glossType: { value: e.value, label: e.value }})
        }
    }

    toggle = (e) => {
        if (this.state.collapse === false) {
            this.setState({ collapse: true, collapseText: "Expand"})
        } else {
            this.setState({ collapse: false, collapseText: "Collapse"})
        }
    }

    toggleArchived = (e) => {
        this.setState({
            isArchived: !this.state.isArchived
        })
    }

    render() {
        return (
            <Container className="m-4">
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                { this.state.loading && (
                    <div className="loading style-2">
                        <div className="loading-wheel"></div>
                        <h3 className="loading-message">THIS MIGHT TAKE A FEW MINUTES. PLEASE WAIT...</h3>
                    </div>
                )}
                <Alert color="success" isOpen={this.state.success ? true : false} className="text-center">
                    { this.state.success } <br />
                    <Button outline color="primary" size="md" onClick={() => window.location.reload()}> Add New Design</Button>
                </Alert>
                {!this.state.success && (
                    <form action="" className="edit-design" onSubmit={this.handleSubmit}>

                        <FormGroup row>
                            <Col className="col" md={12}>
                                {this.state.boxUVDesign == null && <h3>Add New UV Print Design</h3>}
                                {this.state.boxUVDesign != null && <h3>Edit UV Print Design: {this.state.boxUVDesign.id}</h3>}
                                <label className="col-form-label">Design Name</label>
                                <Input
                                    key="designName"
                                    id="designName"
                                    name="designName"
                                    placeholder="Uv Design Name"
                                    onChange={this.handleChange}
                                    value={this.state.designName} />
                            </Col>
                        </FormGroup>
                        <Collapse isOpen={this.state.collapse === false}>

                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Type</label>
                                    <Select
                                        key="boxType"
                                        name="boxType"
                                        isClearable={true}
                                        value={{ value: this.state.boxType, label: this.state.boxType}}
                                        onChange={this.handleTypeChange}
                                        options={this.state.boxTypes.map((t) => ({ value: t.key, label: t.key, name: "boxType" }))}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Box Side</label>
                                    <Select
                                        key="boxSide"
                                        name="boxSide"
                                        isClearable={true}
                                        value={{ value: this.state.boxSide, label: this.state.boxSide}}
                                        onChange={this.handleSideChange}
                                        options={this.state.boxSides.map((t) => ({ value: t.key, label: t.label, name: "boxSide" }))}
                                    />
                                </Col>
                            </FormGroup>


                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">DO YOU HAVE ANY DATA IN COLOR LAYER?</label>
                                    <Select
                                        key="colorLayer"
                                        name="colorLayer"
                                        isClearable={false}
                                        onChange={this.handleColorChange}
                                        value={this.state.colorLayer}
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.colorCollapse === true}>
                                {/* <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">WILL YOU BE BUYING ADDITIONAL PRINT PASS FOR COLOR LAYER?</label>
                                        <Select
                                            key="printColorLayer"
                                            name="printColorLayer"
                                            isClearable={false}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                        />
                                    </Col>
                                </FormGroup> */}
                            </Collapse>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">DO YOU HAVE ANY DATA IN WHITE LAYER?</label>
                                    <Select
                                        key="whiteLayer"
                                        name="whiteLayer"
                                        isClearable={false}
                                        onChange={this.handleWhiteChange}
                                        value={this.state.whiteLayer}
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.whiteCollapse === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">DID YOU APPLY RDG_WHITE COLOR FROM ROLAND SWATCH INSTEAD OF WHITE COLOR?</label>
                                        <Select
                                            key="rdgWhiteLayer"
                                            name="rdgWhiteLayer"
                                            isClearable={false}
                                            onChange={this.handleRDGWhiteChange}
                                            value={this.state.rdgWhiteLayer}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No (white layer will not print)"}]}
                                        />
                                    </Col>
                                </FormGroup>

                                {/* <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">WILL YOU BE BUYING ADDITIONAL PRINT PASS FOR WHITE LAYER?</label>
                                        <Select
                                            key="printWhiteLayer"
                                            name="printWhiteLayer"
                                            isClearable={false}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                        />
                                    </Col>
                                </FormGroup> */}
                            </Collapse>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">DO YOU HAVE ANY DATA IN GLOSS LAYER?</label>
                                    <Select
                                        key="glossLayer"
                                        name="glossLayer"
                                        isClearable={false}
                                        onChange={this.handleGlossChange}
                                        value={this.state.glossLayer}
                                        options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No"}]}
                                    />
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.glossCollapse === true}>

                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">DID YOU APPLY RDG_GLOSS COLOR FROM ROLAND SWATCH TO YOUR GLOSS LAYER?</label>
                                        <Select
                                            key="rdgGlossLayer"
                                            name="rdgGlossLayer"
                                            isClearable={false}
                                            onChange={this.handleRDGGlossChange}
                                            value={this.state.rdgGlossLayer}
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No" , label: "No  (gloss layer will not print)"}]}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">PLEASE SELECT GLOSS PRINT TYPE </label>
                                        <Select
                                            key="glossType"
                                            name="glossType"
                                            isClearable={false}
                                            onChange={this.handleGlossTypeChange}
                                            value={this.state.glossType}
                                            options={[{ value: "Varnish", label: "VARNISH" }, { value: "Matte" , label: "MATTE"}]}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>

                        </Collapse>
                        <FormGroup row>
                            <Col className="col" md={12}>

                                <Label for="uploadFile">
                                    Upload File
                                    {this.state.boxUVDesign != null && !this.state.fileError &&<span> | <a href={this.state.boxUVDesign.document_url} target="_blank" rel="noopener noreferrer">View Document</a></span>}
                                </Label>
                                <Input type="file" name="uploadFile" id="uploadFile" />
                                <Label className="text-bold text-danger font-weight-bold" >
                                    ONLY PDF FORMAT FILE PLEASE
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-warning" md={12}>
                                <Label for="agreementTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="agreementTerms" falsevalue="true" />
                                    Yes, I carefully read instructions in Tayda site and prepared my file carefully. I understand that Tayda can't edit my file even if there is something wrong with it visibly and Tayda will print the file as it is. Instructions Page: <a href="https://www.taydaelectronics.com/hardware/enclosures/enclosure-uv-printing-service.html" title="Tayda UV Instructions" target="_blank"  rel="noopener noreferrer">https://www.taydaelectronics.com/hardware/enclosures/enclosure-uv-printing-service.html</a>
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-danger" md={12}>
                                <Label for="doubleCheckTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="doubleCheckTerms" falsevalue="true" />
                                    I double checked and I confirm that everything in this form is correct and I understand that no matter what I won't be able to request or change anything about this order after I click Submit Order button.
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-info" md={12}>
                                <Label for="sizeAgreementTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="sizeAgreementTerms" falsevalue="true" />
                                    I made sure my file size is correct. (For 125B enclosure 62x117mm , 1590B enclosure 56x108.50mm, 1590A enclosure 35x89mm, 1590BB enclosure 90x115.50mm, 1590XX enclosure 117x141mm, 1590DD enclosure 117x185mm.)
                                    We often have received the wrong artboard / artwork size files and this can delay your UV print job by weeks, or even a month and print result can look very bad. Please double check properties of your pdf file to see it is correct size.
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            {this.state.boxUVDesign &&
                                <Col className="col text-left" md={6}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="isArchived" name="isArchived" key="isArchived" checked={this.state.isArchived} onChange={this.toggleArchived} />
                                        <label className="custom-control-label" htmlFor="isArchived">Archive</label>
                                    </div>
                                </Col>
                            }
                            <Col className="col text-right" md={this.state.boxUVDesign ? 6 : 12}>
                                <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                                <Button color="success" type="submit">Save UV Design</Button>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Container>
        );
    }
}

export default CustomerUvDesignForm;