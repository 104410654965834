import React, { Component } from 'react';
import Line from './Line';
import {
    Row,
    Col,
    Container,
    Input,
    Label
} from 'reactstrap';
import Select from 'react-select';
import {getDefaultBoxType, getDefaultBoxSide} from '../../box-helpers';
import Button from 'reactstrap/lib/Button';

class LineFormItems extends Component {
    boxSides = [
        {
            key: "A",
            label: "A (Face)"
        },
        {
            key: "B",
            label: "B (Top)"
        },
        {
            key: "C",
            label: "C (Left)"
        },
        {
            key: "D",
            label: "D (Bottom)"
        },
        {
            key: "E",
            label: "E (Right)"
        },
        {
            key: "Lid",
            label: "Lid"
        }
    ];
    state = {
        lineId: 0,
        boxType: getDefaultBoxType(),
        boxSide: getDefaultBoxSide(),
        diameterColor: 'input-default',
        hasSizeError: false,
        positionX1: 0,
        positionX2: 50,
        positionY1: 0,
        positionY2: 0
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSideChange = this.handleSideChange.bind(this)
        this.state.lineId = props.line.props.lineId;
        this.state.boxSide = props.line.props.boxSide;
        this.state.positionX1 = props.line.props.positionX1;
        this.state.positionX2 = props.line.props.positionX2;
        this.state.positionY1 = props.line.props.positionY1;
        this.state.positionY2 = props.line.props.positionY2;
    }

    handleDelete = (e) => {
        e.preventDefault();
        console.log('delete', this.state.lineId)
        this.props.resetLines(this.state.lineId);
        this.props.removeLineFormItem(this.state.lineId);

    }

    handleChange = (e) => {
        e.preventDefault();
        // console.log("et", e.target.dataset.type);
        // console.log("e", e.target.value);

        this.props.resetLines(this.state.lineId)

        if (e.target.dataset.type === "diameter" && e.target.value && e.target.value < 3) {
            this.setState({diameterColor: "input-danger", hasSizeError: true})
        } else {
            this.setState({diameterColor: "input-default", hasSizeError: false})
        }

        let dataType= e.target.dataset.type;
        let lineId= e.target.dataset.id;
        this.setState({ [dataType]: e.target.value }, () => {
            const line = <Line key={lineId} lineId={lineId} {...this.state} />
            // console.log("changeline", line)
            this.props.addLine(line);
        })

    }

    handleSideChange = (e) => {

        this.props.resetLines(this.state.lineId)


        this.setState({boxSide: e.value}, () => {
            const line = <Line key={this.state.lineId} {...this.state} />
            // console.log("eline", line);
            this.props.addLine(line);
        })

        this.props.hasLidChange(this.state.lineId, e.value, "line");

    }

    render()  {
       const idx = this.props.idx;
       const key = this.props.lineKey;

       return (
        <Container key={"line-wrapper-" + key}>

            <Row className="form-inline form-short">
                <Col className="col pr-0" md={3}>
                    <Label className="line-label">Line #{idx} -  Side</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Label className="line-label">X1 (mm) Position</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Label className="line-label">Y1 (mm) Position</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Label className="line-label">X2 (mm) Position</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Label className="line-label">Y2 (mm) Position</Label>
                </Col>
                <Col className="col pr-0" md={1}>
                </Col>

            </Row>
            <Row className="form-inline form-short">
                <Col className="col pr-0" md={3} style={{ minWidth: '18%'}}>
                    <Select
                        key={"lineSide"+idx}
                        id={"line-side-"+idx}
                        name={"lineSide"+idx}
                        data-id={idx} data-type="boxSide"
                        isClearable={false}
                        value={{ value: this.state.boxSide, label: this.state.boxSide}}
                        onChange={this.handleSideChange}
                        options={this.boxSides.map((t) => ({ value: t.key, label: t.label }))}
                    />
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.positionX1} onChange={this.handleChange}  id={"line-x1value-"+idx} key={"linePositionX1"+idx} name={"linePositionX1"+idx} data-type="positionX1" type="number" placeholder="X1 Value" step="any" className="line-input-field" />
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.positionY1} onChange={this.handleChange}  id={"line-y1value-"+idx} key={"linePositionY1"+idx} name={"linePositionY1"+idx} data-type="positionY1"  type="number" placeholder="Y1 Value" step="any" className="line-input-field" />
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.positionX2} onChange={this.handleChange}  id={"line-x2value-"+idx} key={"linePositionX2"+idx} name={"linePositionX2"+idx} data-type="positionX2" type="number" placeholder="X2 Value" step="any" className="line-input-field" />
                </Col>
                <Col className="col pr-0" md={2}>
                    <Input value={this.state.positionY2} onChange={this.handleChange}  id={"line-y2value-"+idx} key={"linePositionY2"+idx} name={"linePositionY2"+idx} data-type="positionY2"  type="number" placeholder="Y2 Value" step="any" className="line-input-field" />
                </Col>
                <Col className="col pr-0" md={1}>
                    <Button onClick={this.handleDelete} color="danger">x</Button>
                </Col>

            </Row>
        </Container>
       );
   }
}

export default LineFormItems;