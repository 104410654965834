import React from 'react';
import { getWidth, getHeight,getPositionXCenter, getPositionYCenter, getDefaultBoxSide } from '../../box-helpers';


class Line extends React.Component {

  state = {
    boxSide: getDefaultBoxSide(),
    positionX1: -10,
    positionY1: 0,
    positionX2: 10,
    positionY2: 0,
  }

  constructor(props) {
    super(props);

    this.state.boxSide = props.boxSide;
    this.state.positionX1 = props.positionX1;
    this.state.positionY1 = props.positionY1;
    this.state.positionX2 = props.positionX2;
    this.state.positionY2 = props.positionY2;

  }

  getPositionX1() {
    const lineX1inPixels =  +this.props.positionX1*3.78;
    return +getPositionXCenter(this.props.boxType, this.props.boxSide) + +lineX1inPixels;
  }

  getPositionY1() {
    const lineY1inPixels =  +this.props.positionY1*3.78*-1;
    return +getPositionYCenter(this.props.boxType, this.props.boxSide) + +lineY1inPixels;
  }

  getPositionX2() {
    const lineX2inPixels =  +this.props.positionX2*3.78;
    return +getPositionXCenter(this.props.boxType, this.props.boxSide) + +lineX2inPixels;
  }

  getPositionY2() {
    const lineY2inPixels =  +this.props.positionY2*3.78*-1;
    return +getPositionYCenter(this.props.boxType, this.props.boxSide) + +lineY2inPixels;
  }

  getFillColor() {
    let width = getWidth(this.props.boxType, this.props.boxSide);
    let height = getHeight(this.props.boxType, this.props.boxSide);

    let positionX1 = (+this.props.positionX1*3.78);
    let positionX2 = (+this.props.positionX2*3.78);
    let positionY1 = (+this.props.positionY1*3.78);
    let positionY2 = (+this.props.positionY2*3.78);

    // console.log('center', centerX);
    // console.log('width', width/2);
    // console.log('positionX', Math.abs(positionX));
    // console.log('cw-', (width/2)-radiusInPixels-7.56);
    // console.log('center', centerY);
    // console.log('height', height);
    // console.log('positionY', Math.abs(positionY));
    // console.log('cy-', (height/2)-radiusInPixels-7.56);

    if ((Math.abs(positionX1) > (width/2)) || (Math.abs(positionX2) > (width/2)) || (Math.abs(positionY1) > (height/2)) || (Math.abs(positionY2) > (height/2)) ){
        return "red";
    } else if (this.props.lineColor != undefined){
        return this.props.lineColor;
    } else {
        return "blue";
    }
}

  render () {
    return (
      <line x1={this.getPositionX1()} y1={this.getPositionY1()} x2={this.getPositionX2()} y2={this.getPositionY2()}  style={{stroke: this.getFillColor(),strokeWidth:2}} />
    );
  }
}

export default Line;