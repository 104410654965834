import React, { Component } from "react";
import ReactPaginate from 'react-paginate';
import moment from 'moment';

import {
    Table,
    Form, FormGroup, Label,
    Input, InputGroup, InputGroupAddon, InputGroupText,
    UncontrolledPopover, PopoverHeader, PopoverBody,
    UncontrolledTooltip
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faShareAltSquare, faFilePdf, faCopy, faClone, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Pagination extends Component {
    state = {
        data: [],
        page_count: 0,
        page_size: 10,
        page: 0,
        tooltipOpen: false,
        popoverOpen: false,
        setTooltipOpen: false
    }

    constructor(props){
        super(props);

        this.URL = this.props.url;
        this.DATA_ATTR = this.props.data_attr
        this.REDIRECT_TO = this.props.redirect_to;

        this.SHOW_ARCHIVED = this.props.show_archived ? 1 : 0;
        this.SHOW_INCOMPLETE = this.props.show_incomplete ? 1 : 0;

        this.PAGE_SIZE_KEY = this.props.page_size_key;
        let page_size = localStorage.getItem(this.PAGE_SIZE_KEY) || 10;
        this.state.page_size = page_size;

        this.getList(1);
    }

    getList = async (new_page) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(this.URL + '?page=' + new_page + '&page_size=' + this.state.page_size + "&show_archived=" + this.SHOW_ARCHIVED + "&show_incomplete=" + this.SHOW_INCOMPLETE, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            return data;
        })
        .catch((error) => {
            return error;
        });
        // console.log(result);
        if(result["meta"]){
            this.setState({
                data: result[this.DATA_ATTR] ?  result[this.DATA_ATTR] : [],
                page_count: result["meta"]['page_count'],
                page: new_page - 1
            });
        }
    }

    handlePageClick = (data) => {
        //console.log(data);
        this.getList(data.selected + 1);
    }

    handleChange = (e) => {
        localStorage.setItem(this.PAGE_SIZE_KEY, e.target.value);
        this.setState({
            page_size: e.target.value
        }, () => {
            this.getList(1);
        })
    }

    getIndex = (index) => {
        if(this.state.page == 0){
            return (index + 1);
        }else{
            return (this.state.page*this.state.page_size) + (index + 1);
        }
    }

    toggleTooltip = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen})
        return this.state.toggleTooltip
    }

    togglePopover = () => {
        this.setState({popoverOpen: !this.state.popoverOpen})
        return this.state.togglePopover
    }

    selectShareUrl = (event) => {
        if (this.state.popoverOpen) {
            event.target.select();
        }
    }


    copyDataToClipboard = (data) => {
        let share_url = this.shareUrl(data);
        navigator.clipboard.writeText(share_url)
        toast.success('Copied share link to clipboard');
    }

    shareUrl = (data) => {
        return window.location.origin + "/box-designs/new?public_key=" + data
    }

    render() {
        return (
            <div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                {this.state.data.length > 0 &&
                    <div>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{width: '2%'}}></th>
                                    <th style={{width: '23%'}}>Name</th>
                                    <th style={{width: '15%'}}>Enclosure</th>
                                    <th style={{width: '15%'}}>Created</th>
                                    <th style={{width: '25%'}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((item, index) => {

                                        return <tr key={item.id}>
                                            <td>{this.getIndex(index)}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.enclosure_type}
                                                {item["box_side"] &&
                                                    <div>Side: {item["box_side"]}</div>
                                                }
                                            </td>
                                            <td>
                                                <div className="text-small">{moment(item.created_at).format("MMM DD YYYY h:mm")}</div>
                                            </td>
                                            <td className="text-left align-middle">
                                                {item["active_box_job"] === true &&
                                                    <div className="my-2">
                                                        <span id={"InProgressTooltip"+ item.id}><FontAwesomeIcon className="fa-md" icon={faInfoCircle} />Job In Progress</span>
                                                        <UncontrolledTooltip placement="left" target={"InProgressTooltip"+ item.id}>
                                                            Design templates can be edited until they are assigned to an in progress box job.
                                                        </UncontrolledTooltip>

                                                    </div>
                                                }
                                                {item["file_error"] &&
                                                    <div className="my-2">
                                                        <span id={"FileErrorTooltip"+ item.id}><FontAwesomeIcon className="fa-md" color="red" icon={faExclamationTriangle} /></span>
                                                        <UncontrolledTooltip placement="left" target={"FileErrorTooltip"+ item.id}>
                                                            {item["file_error"]}
                                                        </UncontrolledTooltip>

                                                    </div>
                                                }

                                                {item["document_url"] && !item["file_error"] &&
                                                    <span className="m-2">
                                                        <a href={item.document_url} id={"DownloadTooltip"+ item.id} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="fa-lg" icon={faFilePdf} /></a>
                                                        <UncontrolledTooltip placement="bottom" target={"DownloadTooltip"+ item.id}>
                                                            Download Design
                                                        </UncontrolledTooltip>
                                                    </span>
                                                }

                                                {item["active_box_job"] === false &&
                                                    <span className="m-2">
                                                        <a href={this.REDIRECT_TO + "/edit?id=" + item.id} id={"EditTooltip"+ item.id} title="Edit Design"><FontAwesomeIcon className="fa-lg" icon={faEdit} /></a>
                                                        <UncontrolledTooltip placement="bottom" target={"EditTooltip" + item.id}>
                                                            Edit Design
                                                        </UncontrolledTooltip>
                                                    </span>
                                                }

                                                {item["box_design_holes"] &&
                                                    <span className="m-2">
                                                        <a href={this.REDIRECT_TO + "/new?id=" + item.id} id={"DuplicateTooltip"+ item.id} title="Duplicate Design"><FontAwesomeIcon className="fa-lg" icon={faClone} /></a>
                                                        <UncontrolledTooltip placement="bottom" target={"DuplicateTooltip"+ item.id}>
                                                            Duplicate Design
                                                        </UncontrolledTooltip>
                                                    </span>
                                                }

                                                {item["public_key"] != null &&
                                                    <span className="m-2">
                                                        <a href="#" id={"ShareTooltip"+ item.id}><FontAwesomeIcon id={"Popover1"+ item.id} className="fa-lg text-primary" onClick={(e) => {e.preventDefault();}} icon={faShareAltSquare} /></a>
                                                        <UncontrolledTooltip placement="right" target={"ShareTooltip"+ item.id}>
                                                            Share Design
                                                        </UncontrolledTooltip>
                                                        <UncontrolledPopover className="share-popup" placement="left" target={"Popover1"+ item.id}>
                                                            <PopoverHeader>Share Link</PopoverHeader>
                                                            <PopoverBody>
                                                                <div>
                                                                <InputGroup>
                                                                    <Input readOnly className="share-url float-left p-1" key="share_url" id="share_url" name="share_url" placeholder="Share Url" onClick={this.selectShareUrl} value={this.shareUrl(item.public_key)} />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText><FontAwesomeIcon className="fa-lg float-left text-primary" onClick={(e) => {e.preventDefault(); this.copyDataToClipboard(item.public_key)}} icon={faCopy} /></InputGroupText>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                                </div>
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-between">
                            { this.state.page_count > 1 && (
                                <div>
                                    <div style={{padding: '4px 0px'}}>
                                        <Form>
                                            <FormGroup className="form-inline form-short">
                                                <Label className="mr-1">Shows: </Label>
                                                <Input type="select" name="select" id="exampleSelect" onChange={this.handleChange} value={this.state.page_size}>
                                                    <option>10</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                </Input>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                    <ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.page_count}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }

}
export default Pagination;