import React, { Component } from 'react';
import {
    // Row,
    Col,
    Container,
    Input,
    FormGroup,
    Label,
    // Form,
    Alert,
    Button,
    Collapse
} from 'reactstrap';
import Select from 'react-select';
import HoleFormItems from './HoleFormItems';
import Hole from './Hole';
import queryString from 'query-string';
import {getDefaultBoxType, getDefaultBoxSide, getBoxTypes, getApiUrl } from '../../box-helpers';

class AddDesignFormV3 extends Component {

    state ={
        boxType: '',
        boxTypes: getBoxTypes(),
        skus: [],
        allEnclosures: [],
        enclosures: [],
        availableQuantities: [],
        orderNumber: '',
        designName: '',
        sku: '',
        skulabel: '',
        enclosureSku: '',
        enclosureSkulabel: '',
        color: '',
        quantity: '',
        holeInputs: [],
        deletedCount: 0,
        success: '',
        collapse: false,
        collapseText: "Collapse",
        errors: {
            orderNumber: '',
            sku: '',
            color: '',
            quantity: '',
            boxType: '',
            enclosureSku: '',
            holes: {},
            form: ''
        }

    }

    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        // console.log('holes', this.props.holes)
        const parsed = queryString.parse(window.location.search);
        if (parsed.orderNumber) {
            this.state.orderNumber = parsed.orderNumber
        }
        if (parsed.sku) {
            this.state.sku = parsed.sku
        }
        if (parsed.color) {
            this.state.color = parsed.color
        }
        if (parsed.quantity) {
            this.state.quantity = parsed.quantity
        }
        this.state.boxType = props.boxType;
        this.state.holeInputs = Object.keys(this.props.holes).map((key, idx) => {
            return <HoleFormItems key={`hole-items-${key}`} hole={this.props.holes[key]} idx={idx+1} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} />
        })
    }

    removeHoleFormItem = (holeFormKey) => {
        var array = [...this.state.holeInputs];
        //console.log('hfk', holeFormKey);
        let deletedInputs = this.state.deletedCount;
        let newholeInputs = array.filter((a) => a.props.holeId !== holeFormKey)
        this.setState({
            holeInputs: newholeInputs,
            deletedCount: +deletedInputs+1
        })
    }

    addHoleFormItems = () => {
        const holeInputs = {...this.state.holeInputs };
        const boxType = this.state.boxType;
        let deletedCount = this.state.deletedCount;

        const arr = Object.keys(holeInputs).map((key) => {
            if (key) {
                return holeInputs[key];
            }
        });
        const key = Object.keys(holeInputs).length;
        const holeId = (key + 1) + deletedCount;
        const hole = <Hole key={`hole${holeId}`} boxType={boxType} boxSide={getDefaultBoxSide()} holeId={`hole${holeId}`} diameter="3" positionX="0" positionY="0" />
        arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} />)

        this.setState({ holeInputs: arr })

        this.props.addHole(hole);

    }

    handleSubmit = (e) => {
        e.preventDefault();
        let msg = this.validateFormValues(e.target);
        let errors = this.state.errors

        let isValid = this.validateForm(this.state.errors)

        if(isValid && msg === '') {
            errors.form = false
            this.setState({errors})
            const holeInputs = {...this.state.holeInputs };

            const holes = Object.keys(holeInputs).map((key, idx) => {
                let keyIdentifier = holeInputs[key].props.idx;
                // console.log('holeInputs', keyIdentifier);

                let holeSide = 'holeSide'+ keyIdentifier;
                let holeDiameter = 'holeDiameter'+ keyIdentifier;
                let holePositionX = 'holePositionX'+ keyIdentifier;
                let holePositionY = 'holePositionY'+ keyIdentifier;
                // Add this to validate no removed input in state
                if (e.target[holeSide] && e.target[holeDiameter] && e.target[holePositionX] && e.target[holePositionY]) {
                    //console.log('key', key);
                    return {
                        box_side: e.target[holeSide].value,
                        diameter: e.target[holeDiameter].value,
                        positionX: e.target[holePositionX].value,
                        positionY: e.target[holePositionY].value,
                    }
                }
            });
            // console.log("holes", holes)
            // send email
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    order_number: e.target.orderNumber.value.trim(),
                    name: e.target.designName.value.trim(),
                    sku: e.target.sku.value,
                    enclosure_type: e.target.boxType.value,
                    enclosure_sku: e.target.enclosureSku.value,
                    color: e.target.color.value,
                    quantity: e.target.quantity.value,
                    holes: holes
                })
            };

            // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
            fetch(getApiUrl(), requestOptions)
                .then(this.handleErrors)
                .then(data => {
                    if (this.state.errors.form !== "" && !data.message.includes('Success')) {
                        errors.form = data.message;
                        this.setState({  errors })
                    } else {
                        window.scrollTo(0, 0)
                        this.setState({ success: data.message })
                    }
                }).catch(function(error) {
                    console.log(error);
                });
        }
    }

    handleErrors = (response) => {
        let errors = this.state.errors
        if (!response.ok) {
            errors.form = "ERROR"
            this.setState({errors})
            // throw Error(response.statusText);
        }
        return response.json();
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateFormValues = (formObj) => {
        let msg = '';
        if (formObj.orderNumber.value.trim() === '') {
            msg = 'Invalid Order Number!';
        } else if (formObj.designName.value.trim() === '') {
            msg = 'Please add a name to your design';
        } else if (formObj.agreementTerms.checked !== true) {
            msg = 'Please agree to terms by checking the checkbox';
        } else {
            let diameterInput = 1;
            for (let i = 0; i < formObj.length; i++) {
                if (formObj.elements[i].getAttribute("name") === "holeDiameter"+diameterInput) {
                    // console.log("fo" + diameterInput, formObj.elements[i].getAttribute("name"))
                    diameterInput += 1;
                    if (!formObj.elements[i].value || formObj.elements[i].value < 3) {
                        msg = "Minimum hole diameter is 3mm. Make sure the hole diameter inputs have a value."
                    }
                }
            }
            // console.log('HINPUTS', this.state.holeInputs);
        }
        let errors = this.state.errors
        errors.form = msg
        this.setState({errors})

        return msg;
    }

    validateFormFields = (name, value) => {
        let errors = this.state.errors;
        switch (name) {
            case 'orderNumber':
                errors.orderNumber =
                value.length < 8
                    ? 'Order Number must be at least 8 characters long!'
                    : '';
                break;
            case 'color':
                errors.color = '';
                break;
            case 'sku':
                errors.sku =
                value.length === 0
                    ? 'Drill Product required'
                    : '';
                break;
            case 'enclosureSku':
                errors.enclosureSku =
                value.length === 0
                ? 'Enclosure Product required'
                : '';
                break;
            case 'quantity':
                errors.quantity = '';
                break;
            case 'designName':
                errors.designName =
                value.length === 0
                ? 'Design Name required'
                : '';
                break;

            default:
                break;
        }
        // if (name != 'color') {
        //     errors.color = '';
        // }
        return errors;
    }

    handleOrderChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            if (value.length >8) {
            // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
            fetch(getApiUrl() + "/search?order_number="+value, requestOptions)
                .then(this.handleErrors)
                .then(data => {
                    if (this.state.errors.form !== "" && !data.message.includes('Success')) {
                        errors.form = data.message;
                        this.setState({  errors })
                    } else {
                        // console.log('data', data);
                        this.setState({ sku: "", enclosureSku: "", skulabel: "", enclosureSkulabel: "", skus: [], allEnclosures: [], enclosures: [], availableQuantities: [] })
                        let quantity = 0
                        if (data && data.drill_products && data.drill_products.length > 0) {
                            let drill_products = data.drill_products.reduce((results, key) => {
                                if (key.available_quantity > 0) {
                                    quantity = key.available_quantity;
                                    results.push({
                                        value: key.sku,
                                        label: key.available_quantity + " - " +key.name + " " + key.sku,
                                        available_quantity: key.available_quantity,
                                        box_type: key.attribute_value,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])
                            // console.log('drill_products', drill_products);
                            let availableQuantities = []
                            for (let i = 1; i <= quantity; i++) {
                                availableQuantities.push(i);
                            }
                            // console.log('availableQuantities', availableQuantities)
                            this.setState({ skus: drill_products, availableQuantities: availableQuantities })

                        }

                        if (data && data.enclosure_products && data.enclosure_products.length > 0) {
                            // console.log("Drill", data.enclosure_products)
                            let enclosure_products = data.enclosure_products.reduce((results, key) => {
                                // console.log("key1", key)
                                if (key.available_drill_quantity > 0) {
                                   results.push({
                                        value: key.sku,
                                        label: key.available_drill_quantity + " - " +key.name + " " + key.sku,
                                        box_type: key.attribute_value,
                                        available_quantity: key.available_drill_quantity,
                                        color: key.enclosure_color,
                                        product_id: key.product_id
                                    });
                                }
                                return results;
                            }, [])
                            // console.log('enclosure_products', enclosure_products);
                            this.setState({ allEnclosures: enclosure_products })
                        }

                    }
                }).catch(function(error) {
                    console.log(error);
                });
            }
            this.setState({errors, [name]: value})
        }
    }

    handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);
            this.setState({errors, [name]: value})
        }
    }


    handleSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            this.setState({[name]: value, [name+"label"]: label})
        }
    }

    handleSkuSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            let currentEnclosures = this.state.allEnclosures
            let availableEnclosures = []
            if (currentEnclosures.length > 0) {
                let isCombo = false;
                this.setState({ enclosureSku: "", enclosureSkulabel: "" })
                availableEnclosures  = currentEnclosures.reduce((result, key) => {
                    // console.log('key', key)
                    if (key && key.product_id === e.product_id) {
                        result = [{ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id }]
                        isCombo = true
                    } else if (isCombo === false && key && key.box_type === e.box_type) {
                        result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id  })
                    }
                    return result;
                }, [])
            }
            if (availableEnclosures.length === 0) {
                availableEnclosures = [{value: 0, label: "No Enclosures available"}]
            }

            this.setState({[name]: value, [name+"label"]: label, enclosures: availableEnclosures})
        }
    }

    handleTypeChange = (e) => {
        if (e) {
            // console.log(e);
            const {name, value, label} = e;
            if (e.available_quantity != undefined) {
                let availableQuantities = []
                for (let i = 1; i <= e.available_quantity; i++) {
                    availableQuantities.push(i);
                }
                this.setState({quantity: e.available_quantity, availableQuantities: availableQuantities})
            }
            this.setState({boxType: e.box_type, color: e.color, [name]: value , [name+"label"]: label});
            this.props.handleBoxTypeChangeSelect(e.box_type);
        }
    }

    toggle = (e) => {
        if (this.state.collapse === false) {
            this.setState({ collapse: true, collapseText: "Expand"})
        } else {
            this.setState({ collapse: false, collapseText: "Collapse"})
        }
    }

    render() {
        return (
            <Container className="m-4">
                <Alert color="success" isOpen={this.state.success ? true : false} className="text-center">
                    { this.state.success } <br />
                    <Button outline color="primary" size="md" onClick={() => window.location.reload()}> Add New Design</Button>
                </Alert>
                {!this.state.success && (
                    <form action="" className="add-design" onSubmit={this.handleSubmit}>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <Button outline color="secondary" size="sm" onClick={this.toggle} className="float-right">{ this.state.collapseText }</Button>
                                <label className="col-form-label">Order Number (Starts with 100xxxxxx)</label>
                                <Input key="orderNumber" id="order-number" name="orderNumber" type="number" onChange={this.handleOrderChange} placeholder="Order Number" value={this.state.orderNumber} />
                                <Alert color="warning" isOpen={this.state.errors.orderNumber ? true : false}>{this.state.errors.orderNumber}</Alert>
                            </Col>
                        </FormGroup>
                        <Collapse isOpen={this.state.collapse === false}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Drill Product</label>
                                    <Select
                                        key="sku"
                                        name="sku"
                                        isClearable={false}
                                        value={{ value: this.state.sku, label: this.state.skulabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.skus.map((t) => ({ value: t.value, label: t.label, name: "sku", box_type: t.box_type, product_id: t.product_id }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.sku ? true : false}>{this.state.errors.sku}</Alert>
                                </Col>
                            </FormGroup>
                            <Alert color="warning" className="small-box" >
                                <div className="text-small" style={{ fontWeight:500}}>
                                    PLEASE WAIT AT LEAST 15 MINUTES AFTER PLACING YOUR ORDER TO SUBMIT THIS PAGE OTHERWISE YOU MIGHT GET AN ERROR MESSAGE.
                                </div>
                            </Alert>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Product</label>
                                    <Select
                                        key="enclosureSku"
                                        name="enclosureSku"
                                        isClearable={false}
                                        value={{ value: this.state.enclosureSku, label: this.state.enclosureSkulabel}}
                                        onChange={this.handleTypeChange}
                                        options={this.state.enclosures.map((t) => ({ value: t.value, label: t.label, name: "enclosureSku", box_type: t.box_type,  color: t.color, available_quantity: t.available_quantity }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.enclosureSku ? true : false}>{this.state.errors.enclosureSku}</Alert>
                                </Col>
                            </FormGroup>
                            {/* <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Type</label>
                                    <Select
                                        key="boxType"
                                        name="boxType"
                                        isClearable={true}
                                        value={{ value: this.state.boxType, label: this.state.boxType}}
                                        onChange={this.handleTypeChange}
                                        options={this.state.boxTypes.map((t) => ({ value: t.key, label: t.key }))}
                                    />
                                </Col>
                            </FormGroup> */}
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Type</label>
                                    <Input readOnly key="boxType" id="boxType" name="boxType" placeholder="Box Type" value={this.state.boxType} />

                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Color</label>
                                    <Input readOnly key="color" id="color" name="color" placeholder="Color" value={this.state.color} />
                                    <Alert color="default" isOpen={false}>
                                        <small className="text-xs">
                                            For plain enclosure just put "Bare" name, if your enclosure is powder coated please just put it's color name in here.
                                        </small>
                                    </Alert>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Design Name</label>
                                    <Input
                                        key="designName"
                                        id="designName"
                                        name="designName"
                                        placeholder="Drill Design Name"
                                        onChange={this.handleChange}
                                        value={this.state.designName} />

                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Quantity</label>
                                    {/* <Input key="quantity" id="quantity" name="quantity" onChange={this.handleChange} type="number" placeholder="Quantity" value={this.state.quantity} /> */}
                                    <Select
                                        key="quantity"
                                        name="quantity"
                                        isClearable={false}
                                        value={{ value: this.state.quantity, label: this.state.quantity}}
                                        onChange={this.handleSelectChange}
                                        options={this.state.availableQuantities.map((t) => ({ value: t, label: t, name: "quantity" }))}
                                    />
                                </Col>
                            </FormGroup>
                        </Collapse>
                        <FormGroup row>
                            <Col className="col" md={12}>

                                <label className="col-form-label">Holes</label>
                                <Alert color="warning" className="xs-box">
                                    <small className="text-xs" style={{ fontWeight:500}}>
                                        PLEASE MAKE SURE TO TAKE COORDINATES FROM CENTER OF SIDES IN MILLIMETERS.
                                        PLEASE ADD 0.2MM TO DIAMETER HOLES FOR POWDER COATING THICKNESS.
                                        FOR EXAMPLE IF YOU NEED 8MM DIA HOLE, PLEASE INPUT 8.20. PLEASE USE "." POINT FOR DECIMALS, PLEASE DON'T USE COMMA ","  , FOR EXAMPLE 5.36 , NOT 5,36
                                    </small>
                                </Alert>
                                <div className="hole-form-items">
                                    {this.state.holeInputs}
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col text-right" md={12}>
                                <Button color="primary" className="btn btn-sm mx-3" onClick={this.addHoleFormItems} >+ Add Hole</Button>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col alert alert-warning" md={12}>
                                <Label for="agreementTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="agreementTerms" falsevalue="true" />
                                    I double checked and I confirm that everything in this form is correct and I understand that no matter what I won't be able to request or change anything about this order after I click Submit Order button.
                                </Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col text-right" md={12}>
                                <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                                <Button color="success" type="submit">Submit Order</Button>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Container>
        );
    }
}

export default AddDesignFormV3;