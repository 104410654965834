import React, { Component } from "react";

import {
    Row, Col
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalf, faStarHalfAlt} from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

const YELLOW = "#ffab00";
const GRAY = "#808080"

class StarRating extends Component {
    constructor(props){
        super(props);

        this.state = {
            size: this.props.size ? this.props.size : "lg",
            rating: this.props.rating ? this.props.rating : 0
        }
    }

    generateRatings = (rating) => {
        let rate = parseFloat(rating);

        let rating_container = [];
        for(let i=0; i<5; i++){
            if(i < rate){
                rating_container.push(
                    <Col className="text-center" key={i} onClick={() => this.changeRating(i+1)}>
                        <FontAwesomeIcon icon={faStar} size={this.state.size} color={YELLOW} />
                    </Col>
                )
            }else{
                rating_container.push(
                    <Col className="text-center" key={i} onClick={() => this.changeRating(i+1)}>
                        <FontAwesomeIcon icon={farStar} size={this.state.size} color={YELLOW} />
                    </Col>
                ) 
            }
        }

        return rating_container;
    }

    changeRating = (new_rating) => {
        if(!this.props.onRatingChange) return;
        
        this.setState({
            rating: new_rating
        }, () => {
            if(this.props.onRatingChange){
                this.props.onRatingChange(new_rating)
            }
        })
    }

    render() {
        return (
            <Row className="w-75">
                {this.generateRatings(this.state.rating)}
            </Row>
        )
    }

}
export default StarRating;