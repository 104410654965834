import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
    Row,
    Col,
    Container,
    // Input,
    // FormGroup,
    // Label,
    // Form,
    // Button
} from 'reactstrap';
import {getDefaultBoxType} from "../../box-helpers";
import CustomerUvDesignForm from "./CustomerUvDesignForm";

class BoxUvDesignWrapper extends Component {
    state = {
        boxType: getDefaultBoxType()
    }

    constructor(props) {
        super(props);
        this.handleBoxTypeChangeSelect = this.handleBoxTypeChangeSelect.bind(this)
        document.title = "Tayda Electronics UV"
    }

    handleBoxTypeChangeSelect = (val) => {
        // console.log('handleBoxTypeChangeSelect', val);
        if (val) {
            this.setState({ boxType: val })
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row>
                        <Col className="col pl-0" lg={{ size:6, offset:3 }} md={{ size: 12, offset:0 }} sm={12} xs={12}>
                            <div id="form-wrapper">
                                <CustomerUvDesignForm handleBoxTypeChangeSelect={this.handleBoxTypeChangeSelect} boxType={this.state.boxType} boxTypes={this.boxTypes} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default BoxUvDesignWrapper;