import React from 'react';
import { getWidth, getPositionXCenter, getPositionYCenter, getDefaultBoxSide, getHeight } from '../../box-helpers';

class Hole extends React.Component {

    state = {
        boxSide: getDefaultBoxSide(),
        diameter: 2,
        positionX: 0,
        positionY: 0
    }

    constructor(props) {
        super(props);

        this.state.holeId = props.holeId;
        this.state.boxType = props.boxType;
        this.state.boxSide = props.boxSide;
        this.state.diameter = props.diameter;
        this.state.positionX = props.positionX;
        this.state.positionY = props.positionY;
    }

    render() {
        const identifier = this.props.holeId;
        return (
            <circle key={this.state.boxSide +"-"+ identifier} className={this.state.boxSide +"-circle"}
                cx={this.getPositionX()} cy={this.getPositionY()} r={this.getRadius()} fill={this.getFillColor()} />
        );
    }

    getDiameter() {
        const diameterInPixels =  +this.props.diameter*3.78;
        return diameterInPixels;
    }

    getRadius() {
        const radiusInPixels =  (+this.props.diameter/2)*3.78;
        return radiusInPixels;
    }

    getPositionX() {
        const holeXinPixels =  +this.props.positionX*3.78;
        return +getPositionXCenter(this.props.boxType, this.props.boxSide) + +holeXinPixels;
    }

    getPositionY() {
        const holeYinPixels =  +this.props.positionY*3.78*-1;
        return +getPositionYCenter(this.props.boxType, this.props.boxSide) + +holeYinPixels;
    }

    getFillColor() {
        let width = getWidth(this.props.boxType, this.props.boxSide);
        let height = getHeight(this.props.boxType, this.props.boxSide);
        const diameterInPixels =  (+this.props.diameter)*3.78;

        let positionX = (+this.props.positionX*3.78);
        let positionY = (+this.props.positionY*3.78);
        let diameter = this.props.diameter;

        // console.log('center', centerX);
        // console.log('width', width/2);
        // console.log('positionX', Math.abs(positionX));
        // console.log('cw-', (width/2)-radiusInPixels-7.56);
        // console.log('center', centerY);
        // console.log('height', height);
        // console.log('positionY', Math.abs(positionY));
        // console.log('cy-', (height/2)-radiusInPixels-7.56);

         if (diameter < 3){
            return "red";
        } else if ((Math.abs(positionX) > (width/2)-(diameterInPixels/2)) || (Math.abs(positionY) > (height/2)-(diameterInPixels/2)) ){
            return "red";
        } else if (this.props.holeColor != undefined){
            return this.props.holeColor;
        } else {
            return "blue";
        }
    }
}

export default Hole;