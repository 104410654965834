import React from 'react';
import {getPositionX, getPositionY, getLabel } from '../../box-helpers';

class BoxLabel extends React.Component {

    // state = {
    //     boxType: "",
    //     boxSide: ""
    // }
    // constructor(props) {
    //     super(props);
    //     this.state.boxType = props.boxType;
    //     this.state.boxSide = props.boxSide;
    // }

    prepareCoordinates() {
        let coordinates = {
            x: +getPositionX(this.props.boxType, this.props.boxSide)+5,
            y: +getPositionY(this.props.boxType, this.props.boxSide)+15,
        }
        return coordinates;
    }

    render() {

        let coordinates = this.prepareCoordinates();
        return (
            <text key={'label' + this.props.boxSide} className="box-label" {...coordinates} id={this.props.boxSide +"-label"} stroke="#333" >{getLabel(this.props.boxSide)}</text>
        )
    }

}

export default BoxLabel;